import React, { useEffect, useState, useCallback } from "react";
import {
  Input,
  Modal,
  Button,
  Loader,
  Segment,
  Dimmer,
  Grid,
  Icon,
  Header,
} from "semantic-ui-react";
import _ from "lodash";
import axios from "../../../../../../apis/dvdlocator";
import CreateNewFamily from "../../../../../tools/familyManager/CreateNew";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../../../redux/actions/notificationActions";

function AddFamilySelect({ open, close, addingAll, addProductsToFamily }) {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [selectedName, setSelectedName] = useState("");
  const [creatingNew, setCreatingNew] = useState(false);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [families, setFamilies] = useState([]);
  const debouncedSearch = useCallback(
    _.debounce((q) => updateSearchResults(q), 500),
    [families]
  );

  function updateSearchResults(term) {
    if (!term) return setResults(families.slice(0, 5));

    setResults(
      families.filter((item) =>
        item.family.toLowerCase().includes(term.toLowerCase())
      )
    );
  }

  const createNewFamily = (family) => {
    return axios.post(`/families`, { name: family }).then((res) => {
      const id = res.data.id;

      setFamilies((families) => [
        { id, family, member_count: 0, new: true },
        ...families,
      ]);
      setResults((prev) => [
        { id, family, member_count: 0, new: true },
        ...prev,
      ]);

      dispatch(
        addNotification({
          text: "Created new family!",
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    });
  };

  useEffect(() => {
    const loadFamilies = async () => {
      try {
        const res = await axios.get(`/families`);

        setFamilies(res.data);
        setResults(res.data.slice(0, 5));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    setLoading(true);
    loadFamilies();
  }, []);

  const ListItem = ({ familyId, family, memberCount, isNew }) => (
    <li
      onClick={() => {
        setSelectedId(familyId);
        setSelectedName(family);
      }}
      className={`hover-darken  ${isNew && "highlight-once"} ${
        selectedId === familyId && "selected"
      }`}
      style={{
        display: "block",
        margin: "12px 0px",
        padding: "6px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>
          {family} {isNew && <i style={{ color: "gray" }}>(just added)</i>}
        </span>
        <span>({memberCount} items..)</span>
      </div>
    </li>
  );

  return (
    <Modal onClose={close} open={open} size="tiny">
      <CreateNewFamily
        open={creatingNew}
        close={() => setCreatingNew(false)}
        createFamily={createNewFamily}
      />
      <Modal.Header>
        <Grid columns={2}>
          <Grid.Column floated="left" verticalAlign="middle" width={12}>
            <Header size="tiny">
              {addingAll
                ? "Add family to all selected products"
                : "Add family to product"}
            </Header>
          </Grid.Column>
          <Grid.Column floated="right" width={4}>
            <Button
              icon
              labelPosition="left"
              color="green"
              floated="right"
              title="Create new family"
              onClick={() => setCreatingNew(true)}
            >
              <Icon name="plus square" />
              New
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Header>

      <Modal.Content>
        <Input
          placeholder="Search families.."
          size="large"
          onChange={(e) => {
            setSearch(e.target.value);
            // debouncing actual search filtering
            debouncedSearch(e.target.value);
          }}
          value={search}
        />
        {loading ? (
          <Segment padded="very">
            <Dimmer active inverted>
              <Loader active inline="centered" content="Loading" />
            </Dimmer>
          </Segment>
        ) : (
          <ul
            style={{
              backgroundColor: "#e7e9eb",
              padding: "12px 6px",
              maxHeight: "600px",
              overflow: "scroll",
            }}
          >
            {results.map((family) => (
              <ListItem
                key={family.id}
                familyId={family.id}
                family={family.family}
                memberCount={family.member_count}
                isNew={family.new}
              />
            ))}
          </ul>
        )}

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={close}>Cancel</Button>

          <Button
            disabled={!selectedId || submitting}
            color="green"
            onClick={() => {
              setSubmitting(true);
              setSearch("");
              addProductsToFamily(selectedId, selectedName);
            }}
          >
            {addingAll ? "Add to products " : "Add to product"}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default AddFamilySelect;
