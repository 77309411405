import "tippy.js/dist/tippy.css";
import { renderDate } from "../../formatting/dateFormat";
import { renderTitle } from "../../formatting/title";

const cloudfrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

export const masterInventoryColumnSet = [
  {
    label:
      '<Tooltip title = "User designated sort position" position = "bottom" trigger = "click"><p>Sort</p></Tooltip>',
    name: "supply.sort",
    data: "supply.sort",
    title:
      '<Tooltip title = "User designated sort position" position = "bottom" trigger = "click"><p>Sort</p></Tooltip>',
  },
  {
    label: "UPC",
    name: "supply.upc",
    data: "supply.upc",
    title:
      '<Tooltip title = "UPC(Universal Product Code)/ SKU (Stock Keeping Unit)" position = "bottom" trigger = "click"><p>UPC</p></Tooltip>',
  },
  {
    label: "Image",
    name: "supply.supply_image",
    data: "supply.supply_image",
    title:
      '<Tooltip title = "Image of supply unit" position = "bottom" trigger = "click"><p>Image</p></Tooltip>',
  },
  {
    label: "Title",
    name: "supply.supply_title",
    data: "supply.supply_title",
    title:
      '<Tooltip title = "Yom \'s title for item (not Amazon \'s)" position = "bottom" trigger = "click"><p>Title</p></Tooltip>',
    render: (data, type, row) => renderTitle(data),
  },

  {
    label: "ASIN",
    name: "supply.supply_asin",
    data: "supply.supply_asin",
    title:
      '<Tooltip title = "Supply ASIN (Amazon Standard Identification Number)" position = "bottom" trigger = "click"><p>ASIN</p></Tooltip>',
  },
  {
    name: "supply_families_view.families",
    label:
      '<Tooltip title = "Product families this supply unit is a member of." position = "bottom" trigger = "click"><p>Families</p></Tooltip>',
    attr: {
      disabled: true,
    },
    data: "supply_families_view.families",
    title:
      '<Tooltip title = "Product families this supply unit is a member of." position = "bottom" trigger = "click"><p>Families</p></Tooltip>',
    render: function (data, type, row) {
      if (!data) return null;
      if (data.length < 78) return data;
      return `${data.substring(0, 75)}...`;
    },
  },
  {
    label:
      '<Tooltip title = "Part number(s) for this inventory unit" position = "bottom" trigger = "click"><p>Part Number</p></Tooltip>',
    name: "inventory.part_number",
    data: "inventory.part_number",
    title:
      '<Tooltip title = "Part number(s) for this inventory unit" position = "bottom" trigger = "click"><p>Part Number</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Inventory ID for this unit" position = "bottom" trigger = "click"><p>Inventory ID</p></Tooltip>',
    name: "inventory.inventory_id",
    data: "inventory.inventory_id",
    title:
      '<Tooltip title = "Inventory ID for this unit" position = "bottom" trigger = "click"><p>Inventory ID</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Discontinued Date from alliance file" position = "bottom" trigger = "click"><p>Discontinued</p></Tooltip>',
    name: "supply.discontinued_date",
    data: "supply.discontinued_date",
    title:
      '<Tooltip title = "Discontinued Date from alliance file" position = "bottom" trigger = "click"><p>Discontinued</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    name: "media_types[].id",
    label: "Media Types",
    data: "media_types[].id",
    searchable: false,
    orderable: false,
    excludeFromGeneralSearch: true,
    type: "select",
    multiple: true,
    visible: false, // using view column below for display
  },
  {
    name: "supply_media_types_view.media_types",
    title:
      '<Tooltip title = "The media types in this product (Vinyl, Book, CD, DVD, Bluray, 4K)" position = "bottom" trigger = "click"><p>Media Types</p></Tooltip>',
    data: "supply_media_types_view.media_types",
    // using mjoin field above for edit form
  },
  {
    label: "Studio",
    name: "supply.studio",
    data: "supply.studio",
    title:
      '<Tooltip title = "Studio who owns the rights to the title" position = "bottom" trigger = "click"><p>Studio</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label: "Ebay Item ID",
    name: "supply.ebay_itemid",
    data: "supply.ebay_itemid",
    title:
      '<Tooltip title = "Ebay product ID " position = "bottom" trigger = "click"><p>ItemID</p></Tooltip>',
  },
  {
    label: "Walmart SKU",
    name: "supply.walsku",
    data: "supply.walsku",
    title:
      '<Tooltip title = "Walmart SKU" position = "bottom" trigger = "click"><p>WalSKU</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "The availability of supply inventory needed to fulfill this selling option from our distributors. IS=In stock, BO=Back Order, OP=Out of Print" position = "bottom" trigger = "click"><p>Restock</p></Tooltip>',
    name: "supply.restock_status_ftp",
    attr: {
      disabled: true,
    },
    data: "supply.restock_status_ftp",
    title:
      '<Tooltip title = "The availability of supply inventory needed to fulfill this selling option from our distributors. IS=In stock, BO=Back Order, OP=Out of Print" position = "bottom" trigger = "click"><p>Restock</p></Tooltip>',
    render: function (data, type, row) {
      if (data === "999999") return "Misthreading error.";
      if (data === "OP")
        return `<span style="color:#CC0000;font-weight:700;font-size:17px;">OP</span>`;
      if (data === "BO")
        return `<span style="color:#0066FF;font-weight:700;font-size:17px;">BO</span>`;
      if (data === "IS")
        return `<span style="color:#339933;font-weight:700;font-size:17px;">IS</span>`;

      return null;
    },
  },

  {
    label: "Supplier Link",
    name: "supply.sup_lin",
    data: "supply.sup_lin",
    title:
      '<Tooltip title = "Supplier hot links to selected products, or to suppliers not currently being scraped" position = "bottom" trigger = "click"><p>Sup Link</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title = "Listing department queue, and work orders" position = "bottom" trigger = "click"><p>Listed</p></Tooltip>',
    name: "supply.listed",
    data: "supply.listed",
    title:
      '<Tooltip title = "Listing department queue, and work orders" position = "bottom" trigger = "click"><p>Listed</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "ONO (On Order) Inventory that has been ordered through supplier, and has not yet been delivered to our warehouse" position =  "bottom " trigger =  "click "><p>ONO</p></Tooltip>',
    name: "supply.ono",
    data: "supply.ono",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "ONO (On Order) Inventory that has been ordered through supplier, and has not yet been delivered to our warehouse" position =  "bottom " trigger =  "click "><p>ONO</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "The average (mean) cost paid for the product in our warehouse." position =  "bottom " trigger =  "click "><p>AVCG</p></Tooltip>',
    name: "inventory.avcg",
    data: "inventory.avcg",
    title:
      '<Tooltip title =  "The average (mean) cost paid for the product in our warehouse." position =  "bottom " trigger =  "click "><p>AVCG</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },

  {
    label:
      '<Tooltip title =  "AI (Analyst Inventory) Current Inventory On Hand  + On Order Inventory (ONO) " position =  "bottom " trigger =  "click "><p>AI</p></Tooltip>',
    name: "supply.ai",
    data: "supply.ai",
    title:
      '<Tooltip title =  "AI (Analyst Inventory) Current Inventory On Hand  + On Order Inventory (ONO) " position =  "bottom " trigger =  "click "><p>AI</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },

  {
    label:
      '<Tooltip title =  "Analyst/Purchaser work order " position =  "bottom " trigger =  "click "><p>House</p></Tooltip>',
    name: "supply.house",
    data: "supply.house",
    title:
      '<Tooltip title =  "Analyst/Purchaser work order " position =  "bottom " trigger =  "click "><p>House</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "When the product was last scanned into inventory " position =  "bottom " trigger =  "click "><p>Add Date</p></Tooltip>',
    name: "inventory.add_date",
    data: "inventory.add_date",
    title:
      '<Tooltip title =  "When the product was last scanned into inventory " position =  "bottom " trigger =  "click "><p>Add Date</p></Tooltip>',
    render: renderDate,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "When the product was last scanned out of inventory " position =  "bottom " trigger =  "click "><p>Sub Date</p></Tooltip>',
    name: "inventory.sub_date",
    data: "inventory.sub_date",
    title:
      '<Tooltip title =  "When the product was last scanned out of inventory " position =  "bottom " trigger =  "click "><p>Sub Date</p></Tooltip>',
    render: renderDate,
    excludeFromGeneralSearch: true,
  },
  {
    name: "supply.inventory_id",
    data: "supply.inventory_id",
    type: "hidden",
    visible: false,
    excludeFromGeneralSearch: true,
  },
  {
    label: "Shelf",
    name: "inventory.inventory_shelf",
    data: "inventory.inventory_shelf",
    title:
      '<Tooltip title =  "Where the item can be found in our warehouse " position =  "bottom " trigger =  "click "><p>Shelf</p></Tooltip>',
  },
  {
    label:
      '<Tooltip title =  "How much of this item is in our East Aurora warehouse " position =  "bottom " trigger =  "click "><p>Inv</p></Tooltip>',
    name: "inventory.inv",
    data: "inventory.inv",
    attr: {
      disabled: true,
    },
    title:
      '<Tooltip title =  "How much of this item is in our East Aurora warehouse " position =  "bottom " trigger =  "click "><p>Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Alliance Entertainment Distributor inventory " position =  "bottom " trigger =  "click "><p>Dist Inv</p></Tooltip>',
    name: "supply.dist_inv",
    data: "supply.dist_inv",
    title:
      '<Tooltip title =  "Alliance Entertainment Distributor inventory " position =  "bottom " trigger =  "click "><p>Dist Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title =  "Dealsareus Distributor inventory (DIsplayed as #currently on hand - #currently available from their supplier) " position =  "bottom " trigger =  "click "><p>DAU Inv</p></Tooltip>',
    name: "supply.dau_inv",
    data: "supply.dau_inv",
    title:
      '<Tooltip title =  "Dealsareus Distributor inventory (DIsplayed as #currently on hand - #currently available from their supplier) " position =  "bottom " trigger =  "click "><p>DAU Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
  },

  {
    label:
      '<Tooltip title =  "Mila inventory available" position =  "bottom " trigger =  "click "><p>Mila Inv</p></Tooltip>',
    name: "supply.mila_inv",
    data: "supply.mila_inv",
    title:
      '<Tooltip title =  "Mila inventory available" position =  "bottom " trigger =  "click "><p>Mila Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Mila inv\n\nUpdated at: ${row["supply"]["mila_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title =  "Discount Entertainment inventory available" position =  "bottom " trigger =  "click "><p>DE Inv</p></Tooltip>',
    name: "supply.discount_entertainment_inv",
    data: "supply.discount_entertainment_inv",
    title:
      '<Tooltip title =  "Discount Entertainment inventory available" position =  "bottom " trigger =  "click "><p>DE Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Discount Entertainment inv\n\nUpdated at: ${row["supply"]["discount_entertainment_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Gruv supplier inventory available" position =  "bottom " trigger =  "click "><p>Gruv Inv</p></Tooltip>',
    name: "supply.gruv_inv",
    data: "supply.gruv_inv",
    title:
      '<Tooltip title = "Gruv supplier inventory available" position =  "bottom " trigger =  "click "><p>Gruv Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Gruv inv\n\nUpdated at: ${row["supply"]["gruv_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title =  "Wildcard 1 supplier inventory available" position =  "bottom " trigger =  "click "><p>WC1 Inv</p></Tooltip>',
    name: "supply.wildcard1_inv",
    data: "supply.wildcard1_inv",
    title:
      '<Tooltip title =  "Wildcard 1 supplier inventory available" position =  "bottom " trigger =  "click "><p>WC1 Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Wildcard1 inv\n\nUpdated at: ${row["supply"]["wildcard1_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title =  "Wildcard 2 supplier inventory available" position =  "bottom " trigger =  "click "><p>WC2 Inv</p></Tooltip>',
    name: "supply.wildcard2_inv",
    data: "supply.wildcard2_inv",
    title:
      '<Tooltip title =  "Wildcard 2 supplier inventory available" position =  "bottom " trigger =  "click "><p>WC2 Inv</p></Tooltip>',
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Wildcard2 inv\n\nUpdated at: ${row["supply"]["wildcard2_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title =  "The quantity of damage products in order of damage reason 1-2-3." position =  "bottom " trigger =  "click "><p>DMG Inv</p></Tooltip>',
    name: "supply.dmg_inv_display",
    data: "supply.dmg_inv_display",
    title:
      '<Tooltip title =  "The quantity of damage products in order of damage reason 1-2-3." position =  "bottom " trigger =  "click "><p>DMG Inv</p></Tooltip>',
    attr: {
      disabled: true,
    },
    excludeFromGeneralSearch: true,
  },
  {
    label: "Product List Type",
    name: "inventory.prod_list_type",
    data: "inventory.prod_list_type",
    title:
      '<Tooltip title =  "Product List Type" position =  "bottom " trigger =  "click "><p>Product List Type</p></Tooltip>',
  },
  {
    columnDependencies: ["supply.dtb1_supplier"],
    label:
      '<Tooltip title = "DTB (Direct to Buyer) Terminal or Landed Cost of a product (includes any volume discounts, promotions and rebates)" position = "bottom" trigger = "click"><p>DTB1</p></Tooltip>',
    name: "supply.dtb1",
    className: ".dtb",
    data: "supply.dtb1",
    title:
      '<Tooltip title = "DTB (Direct to Buyer) Terminal or Landed Cost of a product (includes any volume discounts, promotions and rebates)" position = "bottom" trigger = "click"><p>DTB1</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supply.dtb1_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data} `;
    },
  },
  {
    columnDependencies: ["supply.dtb2_supplier"],
    label:
      '<Tooltip title = "DTB2 (Supplier with the 2nd lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB2</p></Tooltip>',
    name: "supply.dtb2",
    className: ".dtb",
    data: "supply.dtb2",
    title:
      '<Tooltip title = "DTB2 (Supplier with the 2nd lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB2</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supply.dtb2_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
  },
  {
    columnDependencies: ["supply.dtb3_supplier"],
    label:
      '<Tooltip title = "DTB3 (Supplier with the 3rd lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB3</p></Tooltip>',
    name: "supply.dtb3",
    className: ".dtb",
    data: "supply.dtb3",
    title:
      '<Tooltip title = "DTB3 (Supplier with the 3rd lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB3</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supply.dtb3_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
  },
  {
    columnDependencies: ["supply.dtb4_supplier"],
    label:
      '<Tooltip title = "DTB4 (Supplier with the 4th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB4</p></Tooltip>',
    name: "supply.dtb4",
    className: ".dtb",
    data: "supply.dtb4",
    title:
      '<Tooltip title = "DTB4 (Supplier with the 4th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB4</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supply.dtb4_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supply.dtb5_supplier"],
    label:
      '<Tooltip title = "DTB5 (Supplier with the 5th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB5</p></Tooltip>',
    name: "supply.dtb5",
    className: ".dtb",
    data: "supply.dtb5",
    title:
      '<Tooltip title = "DTB5 (Supplier with the 5th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB5</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supply.dtb5_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supply.dtb6_supplier"],
    label:
      '<Tooltip title = "DTB6 (Supplier with the 6th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB6</p></Tooltip>',
    name: "supply.dtb6",
    className: ".dtb",
    data: "supply.dtb6",
    title:
      '<Tooltip title = "DTB6 (Supplier with the 6th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB6</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supply.dtb6_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supply.dtb7_supplier"],
    label:
      '<Tooltip title = "DTB7 (Supplier with the 7th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB7</p></Tooltip>',
    name: "supply.dtb7",
    className: ".dtb",
    data: "supply.dtb7",
    title:
      '<Tooltip title = "DTB7 (Supplier with the 7th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB7</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supply.dtb7_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },
  {
    columnDependencies: ["supply.dtb8_supplier"],
    label:
      '<Tooltip title = "DTB8 (Supplier with the 8th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB8</p></Tooltip>',
    name: "supply.dtb8",
    className: ".dtb",
    data: "supply.dtb8",
    title:
      '<Tooltip title = "DTB8 (Supplier with the 8th lowest terminal price - see also DTB1)" position = "bottom" trigger = "click"><p>DTB8</p></Tooltip>',
    render: (data, type, row) => {
      if (!data) return "";

      const supplierName = row.supply.dtb8_supplier;
      return `<img title=${supplierName} height="16px" width="16px" src=${cloudfrontUrl}${supplierName}.png /> ${data}`;
    },
    excludeFromGeneralSearch: true,
  },

  {
    label:
      '<Tooltip title = "Alliance Entertainment price" position = "bottom" trigger = "click"><p>Alliance</p></Tooltip>',
    name: "supply.alliance",
    className: ".st",
    data: "supply.alliance",
    title: `<img class='small-icon' title = "Alliance Entertainment price" src=${require("../../../assets/icons/alliance.png")} alt="Alliance" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Amazon supplier scraped price" position = "bottom" trigger = "click"><p>Amazon</p></Tooltip>',
    name: "supply.amazon",
    className: ".st",
    data: "supply.amazon",
    title: `<img class='small-icon' title = "Amazon price" src=${require("../../../assets/icons/amazon.png")} alt="Amazon" />`,

    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "CC Video supplier scraped price" position = "bottom" trigger = "click"><p>CCVideo</p></Tooltip>',
    name: "supply.ccvideo",
    className: ".st",
    data: "supply.ccvideo",
    title: `<img class='small-icon' title = "CCVideo price" src=${require("../../../assets/icons/ccvideo.png")} alt="CCVideo" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Deals Distributor Price" position = "bottom" trigger = "click"><p>Dealsareus</p></Tooltip>',
    name: "supply.dealsareus",
    className: ".st",
    data: "supply.dealsareus",
    title: `<img class='small-icon' title = "Deals R Us price" src=${require("../../../assets/icons/dealsareus.png")} alt="Deals R Us" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Deep Discount supplier scraped price" position = "bottom" trigger = "click"><p>DeepDiscount</p></Tooltip>',
    name: "supply.deepdiscount",
    className: ".st",
    data: "supply.deepdiscount",
    title: `<img class='small-icon' title = "Deep Discount price" src=${require("../../../assets/icons/deepdiscount.png")} alt="Deepdiscount" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Discount Entertainment supplier scraped price" position = "bottom" trigger = "click"><p>Discount Entertainment</p></Tooltip>',
    name: "supply.discount_entertainment",
    className: ".st",
    data: "supply.discount_entertainment",
    title: `<img class='small-icon' title = "Discount Entertainment price" src=${require("../../../assets/icons/discount_entertainment.png")} alt="Discount Entertainment" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Discount Entertainment price\n\nUpdated at: ${row["supply"]["discount_entertainment_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Ebay BlowItOutofHere (Ebay Supplier) scraped price" position = "bottom" trigger = "click"><p>EbayBlowIt</p></Tooltip>',
    name: "supply.blowitebay",
    className: ".st",
    data: "supply.blowitebay",
    title: `<img class='small-icon' title = "Ebay Blowitoutahere price" src=${require("../../../assets/icons/ebayblowit.png")} alt="Ebay Blowit" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "InetVideo Music scraped price (Website / Supplier has ceased operations)" position = "bottom" trigger = "click"><p>HeartLand</p></Tooltip>',
    name: "supply.inetvideo",
    className: ".st",
    data: "supply.inetvideo",
    title: `<img class='small-icon' title = "InetVideo price" src=${require("../../../assets/icons/inetvideo.png")} alt="InetVideo" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Import CDs supplier scraped price" position = "bottom" trigger = "click"><p>ImportCDs</p></Tooltip>',
    name: "supply.importcds",
    className: ".st",
    data: "supply.importcds",
    title: `<img class='small-icon' title = "ImportCDs price" src=${require("../../../assets/icons/importcds.png")} alt="ImportCDs" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Mila supplier scraped price " position = "bottom" trigger = "click"><p>Mila</p></Tooltip>',
    name: "supply.mila",
    className: ".st",
    data: "supply.mila",
    title: `<img class='small-icon' title = "Mila price" src=${require("../../../assets/icons/mila.png")} alt="Mila" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Mila price\n\nUpdated at: ${row["supply"]["mila_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Gruv supplier scraped price (Website / Supplier has ceased operations)" position = "bottom" trigger = "click"><p>Gruv</p></Tooltip>',
    name: "supply.gruv",
    className: ".st",
    data: "supply.gruv",
    title: `<img class='small-icon' title = "Gruv price" src=${require("../../../assets/icons/gruv.png")} alt="Gruv" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Gruv price\n\nUpdated at: ${row["supply"]["gruv_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Wildcard1 product price" position = "bottom" trigger = "click"><p>Wildcard1</p></Tooltip>',
    name: "supply.wildcard1",
    className: ".st",
    data: "supply.wildcard1",
    title: `<img class='small-icon' title = "Wildcard 1 Price" src=${require("../../../assets/icons/wildcard1.png")} alt="WC1 Price" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Wildcard1 price\n\nUpdated at: ${row["supply"]["wildcard1_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Wildcard2 product price" position = "bottom" trigger = "click"><p>Wildcard2</p></Tooltip>',
    name: "supply.wildcard2",
    className: ".st",
    data: "supply.wildcard2",
    title: `<img class='small-icon' title = "Wildcard 2 Price" src=${require("../../../assets/icons/wildcard2.png")} alt="WC2 Price" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Wildcard2 price\n\nUpdated at: ${row["supply"]["wildcard2_data_timestamp"]}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "Rarewaves supplier scraped price" position = "bottom" trigger = "click"><p>Rarewaves</p></Tooltip>',
    name: "supply.rarewaves",
    className: ".st",
    data: "supply.rarewaves",
    title: `<img class='small-icon' title = "Rarewaves price" src=${require("../../../assets/icons/rarewaves.png")} alt="Rarewaves" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "Target supplier scraped price (Website / Supplier has ceased operations)" position = "bottom" trigger = "click"><p>Target</p></Tooltip>',
    name: "supply.target_store",
    className: ".st",
    data: "supply.target_store",
    title: `<img class='small-icon' title = "Target price" src=${require("../../../assets/icons/target.png")} alt="Target" />`,
    excludeFromGeneralSearch: true,
  },
  {
    label:
      '<Tooltip title = "VEI scraped price  (Website / Supplier has ceased operations)" position = "bottom" trigger = "click"><p>VEI</p></Tooltip>',
    name: "supply.vei",
    className: ".st",
    data: "supply.vei",
    title: `<img class='small-icon' title = "VEI price" src=${require("../../../assets/icons/vei.png")} alt="VEI" />`,
    excludeFromGeneralSearch: true,
  },

  {
    label:
      '<Tooltip title = "Walmart Supplier Scraped Price" position = "bottom" trigger = "click"><p>Walmart</p></Tooltip>',
    name: "supply.walmart",
    className: ".st",
    data: "supply.walmart",
    title: `<img class='small-icon' title = "Walmart price" src=${require("../../../assets/icons/walmart.png")} alt="Walmart" />`,
    excludeFromGeneralSearch: true,
    render: (data, type, row) => {
      if (!data) return "";

      return `<span title='Walmart price\n\nUpdated at: ${renderDate(
        row["supply"]["walmart_updated_timestamp"]
      )}'>${data}</span>`;
    },
  },
  {
    label:
      '<Tooltip title = "WowHD Supplier Scraped Price" position = "bottom" trigger = "click"><p>WOWHD</p></Tooltip>',
    name: "supply.wowhd",
    className: ".st",
    data: "supply.wowhd",
    title: `<img class='small-icon' title = "WowHD price" src=${require("../../../assets/icons/wowhd.png")} alt="WowHD" />`,

    excludeFromGeneralSearch: true,
  },
];

/**
 * Returns array of all columns for a given column set.
 * Will use the masterInventoryColumnSet if no column names are given.
 * @param {array} columnNames Optional
 * @returns {array}
 */
export function getColumnSet(columnNames) {
  if (!columnNames) return masterInventoryColumnSet;

  const columns = [];

  columnNames.forEach((col) => {
    const column = masterInventoryColumnSet.find(
      (colDefinition) => colDefinition.data === col
    );
    if (column) columns.push(column);
  });

  const columnsToHide = masterInventoryColumnSet
    .map((col) => col.name)
    .filter((name) => !columnNames.includes(name));

  // adding rest of columns from master set as hidden - edits will be broken otherwise
  const nonViewableColumns = masterInventoryColumnSet
    .filter((col) => columnsToHide.includes(col.data))
    .map((col) => ({ ...col, visible: false, type: "hidden" }));

  return [...columns, ...nonViewableColumns];
}

/**
 * Returns array of only *viewable* columns for a given column set.
 * Will use the masterInventoryColumnSet if no column names are given.
 * @param {array} columnNames Optional
 * @returns {array}
 */
export function getViewableInventoryColumns(columnNames) {
  let columnSet = [];
  if (!columnNames) {
    columnSet = masterInventoryColumnSet.map((c) => c);
  } else {
    columnSet = getColumnSet(columnNames);
  }

  // Adding column dependencies
  columnSet
    .map((c) => c)
    .forEach((col) => {
      if (col.columnDependencies) {
        col.columnDependencies.forEach((name) => {
          if (!columnSet.find((c) => c.data === name))
            columnSet.push({
              data: name,

              visible: false,
            });
        });
      }
    });

  return columnSet;
}
