import React, { useEffect } from "react";
import { Button, Form, Icon, Loader, Modal, TextArea } from "semantic-ui-react";

function MessageModal({ msg, open, setOpen, type, copy }) {
  useEffect(() => {
    let c = document.getElementById("select-box");
    if (c) c.select();
  });
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="small"
    >
      {!msg.length ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Modal.Content>
            {copy ? (
              <Form>
                <h3>{type === "upc" ? "UPCs" : "MSKUs"}</h3>
                <TextArea id="select-box" defaultValue={msg} />
              </Form>
            ) : (
              <p>{msg}</p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" inverted onClick={() => setOpen(false)}>
              <Icon name="checkmark" /> Okay
            </Button>
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
}

export default MessageModal;
