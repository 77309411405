import React from "react";

import { masterColumnSet } from "./aoColumns";

import DataTablesEditor from "../DatatablesEditor/DataTablesEditor";

import { baseURL } from "../../../apis/dvdlocator";
import { renderDate } from "../../formatting/dateFormat";

class AmazonOrders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: this.props.match.params.search
        ? this.props.match.params.search
        : "",
      columns: masterColumnSet,
      colSets: [],

      fields: masterColumnSet,

      options: {
        dom: "Blrtip", // https://datatables.net/reference/option/dom
        processing: true,
        serverSide: true,
        select: true,
        paging: true,
        paginate: true,
        pageLength: 10,
        lengthChange: true,
        scrollX: true,
        scrollY: window.innerHeight - 200,
        scrollCollapse: true,
        autoWidth: true,

        // 	stateSave: true,
        colReorder: true,
        deferRender: true,
        order: [[1, "asc"]],
      },
    };

    this.dataTablesRef = React.createRef();
  }
  componentDidMount() {
    // run initial search if one given
    if (this.state.searchValue) {
      this.dataTablesRef.current.search(this.state.searchValue);
    }
  }
  // search field onChange event handler
  onChangeSearch = (e) => {
    const { value } = e.target;
    const searchValue = value;
    this.setState({ searchValue });
    this.dataTablesRef.current.search(searchValue);
  };

  format = (d) => {
    const columnConfig = [
      "order_id",
      "msku",
      "order_price",
      "order_qty",
      "order_profit",
      "order_proceeds",
      "order_timestamp",
      "order_status",
      "order_country",
    ];

    // Check if order history exists and is not empty
    if (!d.amazon_order_history || d.amazon_order_history.length === 0) {
      return null;
    }

    // Sort and separate the array
    const orderData = [...d.amazon_order_history];
    this.sortArray(orderData);

    // Create table with dynamic headers
    let ret = `<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;" width="1500px">`;

    // Generate header row
    ret +=
      "<tr>" +
      "<td>Order ID:</td>" +
      "<td>MSKU:</td>" +
      "<td>Sale Price:</td>" +
      "<td>Sale Quantity:</td>" +
      "<td>Profit:</td>" +
      "<td>Proceeds:</td>" +
      "<td>Timestamp:</td>" +
      "<td>Status:</td>" +
      "<td>Country:</td>" +
      "</tr>";

    // Generate data rows
    orderData.forEach((order) => {
      ret +=
        "<tr>" +
        columnConfig
          .map((col) => {
            // Handle special formatting for specific columns
            switch (col) {
              case "order_timestamp":
                return `<td>${renderDate(order.order_timestamp * 1000)}</td>`;
              case "order_profit":
              case "order_proceeds":
              case "order_price":
                return `<td>${this.formatCurrency(order[col])}</td>`;
              case "msku":
                return `<td>${d.product.msku}</td>`;
              default:
                return `<td>${order[col] || ""}</td>`;
            }
          })
          .join("") +
        "</tr>";
    });

    ret += "</table>";
    return ret;
  };

  /**
   * Sort array by timestamp in descending order
   * @param {Array} list - List of orders to sort
   */
  sortArray = (list) => {
    list.sort(
      (a, b) => new Date(b.order_timestamp) - new Date(a.order_timestamp)
    );
  };

  /**
   * Format currency values
   * @param {number|null} value - Monetary value to format
   * @returns {string} Formatted currency string
   */
  formatCurrency = (value) => {
    return value !== null
      ? value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "";
  };

  seperateArray = (
    list,
    id,
    price,
    profit,
    proceeds,
    quantity,
    timestamp,
    status,
    countries
  ) => {
    for (let i = 0; i < list.length; i++) {
      id[i] = list[i].order_id;
      price[i] = list[i].order_price;
      profit[i] = list[i].order_profit;
      proceeds[i] = list[i].order_proceeds;
      quantity[i] = list[i].order_qty;
      timestamp[i] = list[i].order_timestamp;
      status[i] = list[i].order_status;
      countries[i] = list[i].order_country;
    }
  };

  customButtons = () => {
    return [
      {
        text: "<span title='Refresh Table'>Refresh</span>",
        action: (e, dt, node, config) => {
          dt.ajax.reload();
        },
      },
    ];
  };

  render() {
    const { fields, columns, options, searchValue } = this.state;

    return (
      <div>
        <div className="ui input">
          <input
            value={searchValue}
            onChange={this.onChangeSearch}
            autoComplete={"off"}
            type="text"
            placeholder="Search ..."
          />
        </div>
        <DataTablesEditor
          key={this.state.render}
          ref={this.dataTablesRef}
          ajax={{
            url: `${baseURL}/datatables/amazon-orders`,
            contentType: "application/json",
            type: "POST",
            data: (d) => {
              d.smartSearch = this.state.searchValue;
              return JSON.stringify(d);
            },
          }}
          editorAjax={{
            url: `${baseURL}/datatables/amazon-orders`,
            contentType: "application/json",
            type: "POST",
            data: function (d) {
              d.columns = d.columns.filter((col) => col.data !== null);
              return JSON.stringify(d);
            },
          }}
          columns={columns}
          options={options}
          fields={fields}
          createButton={false}
          editButton={true}
          removeButton={true}
          customButtons={this.customButtons}
          colVis={false}
          rowExpansion={this.format}
        />
      </div>
    );
  }
}

export default AmazonOrders;
