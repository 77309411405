import React, { Fragment } from "react";
import { debounce } from "lodash";
import { Modal, Icon, Confirm } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactModal from "../../utilities/ReactModal";
import $ from "jquery";
import { amazonCalculator, openLinks } from "./buttonHandlers/buttonHandlers";
import { calcAutoMargin } from "./buttonHandlers/analysis";
import FamilyManagement from "./ContextMenu/Menus/FamilyManagementProduct/FamilyManagement";
import { openFamiliesProduct, openInLink } from "./buttonHandlers/openData";
import { appendUnixDate } from "../_genericButtonHandlers/appendUnixDate";
import { appendValue, clearData } from "./buttonHandlers/appendCol";
import { addNotification } from "../../../redux/actions/notificationActions";
import {
  getViewableProductColumns,
  masterProductColumnSet,
} from "./ptProductColumns";
import _ from "lodash";
import DataTablesEditor from "../DatatablesEditor/DataTablesEditor";
import BarcodeGenerator from "../../utilities/BarcodeGenerator";
import dvdlocator, { baseURL } from "../../../apis/dvdlocator";
import ModalSwitch from "./buttonHandlers/modals/ModalSwitch";
import MessageModal from "../../MessageModal";
import ShelvingLabelGenerator from "../../utilities/ShelvingLabelGenerator";
import { createQueryParamsFromOptions } from "./filterQueryHelper";
import { renderDate } from "../../formatting/dateFormat";
import ProductContextMenu from "./ContextMenu/ProductContextMenu";
import { masterInventoryColumnSet } from "./ptInventoryColumns";
import { CreateProductWizard } from "../../forms/CreateProductWizard/CreateProductWizard";

class ProductTable extends React.Component {
  constructor(props) {
    super(props);

    this.searchDelay = null;
    this.barCodeType = null;
    this.rowData = [];
    this.datatable = null;

    this.datatableOptions = {
      dom: "Brtip", // https://datatables.net/reference/option/dom
      processing: true,
      serverSide: true,
      paging: true,
      select: {
        selector: "td:not(:first-child)",
      },
      pageLength: 100,
      lengthChange: true,
      autoWidth: true,
      scrollY: this.props.tableHeight,
      scrollX: true,
      scrollCollapse: true,
      colReorder: { realtime: false },
      deferRender: true,
      deferLoading: true,
      order: [[1, "asc"]],
      lengthMenu: [25, 50, 100, 300, 500, 1000],
    };

    this.state = {
      creatingProduct: false,
      confirmCloseModalOpen: false,
      modalName: "",
      selectedRowsArray: [],
      appendStatus: false,
      appendErrors: [],
      appendColumnNames: [],
      appendTableNames: [],
      isLoading: false,
      modalIsActive: false,
      msg: "",
      labelModalIsActive: false,
      currentColumnSet: null,
      isOpenMessageModal: false,
      contextMenu: {
        display: false,
        event: null,
        rowsPreselected: false,
        modal: "",
        products: [],
        loading: false,
      },

      dtProductColumns: getViewableProductColumns(),
      dtEditorFields: masterProductColumnSet,

      outputLog: [],
      outputLogClearedTimestamp: null,

      render: true,
    };

    this.dataTablesRef = React.createRef();
  }

  resetColumns(dt) {
    const url = window.location.href;
    // Special case- if viewing a collection, must keep the product family search filter
    const keepFamilySearch = url.includes("/family/");

    const searchInputs = document.querySelectorAll(".header_search_input_dt");
    const nullFilters = document.querySelectorAll(".header_checkbox_input_dt");

    searchInputs.forEach((element) => (element.value = ""));
    nullFilters.forEach((element) => (element.checked = false));

    // Setting all null filters to false (Control)
    this.setState({
      dtProductColumns: this.state.dtProductColumns.map((col) => ({
        ...col,
        filterNulls: false,
      })),
    });

    if (!keepFamilySearch) return dt.columns().search("").draw();

    const familySearch = this.props.match.params.family;
    dt.columns()
      .search("")
      .column("product_families_view.families:name")
      .search(familySearch)
      .draw();
  }

  clearOutputLog = () => {
    this.setState((prev) => ({
      outputLogClearedTimestamp: renderDate(Math.floor(Date.now() / 1000)),
      outputLog: prev.outputLog.filter(
        (_, i) => i === prev.outputLog.length - 1
      ),
    }));
  };

  rerenderTableNewColumnSet() {
    const existingSearches = this.existingColumnSearches();

    let viewableColumns = getViewableProductColumns(
      this.props.currentColumnSetColumns
    );

    viewableColumns.forEach((column) => {
      const match = existingSearches.find((search) => {
        return search.data === column.data;
      });

      if (match) {
        column.search = {
          value: match.search,
        };
      }
    });

    // forces datatable to re-render with updated columns
    this.setState(
      {
        dtProductColumns: viewableColumns,
      },
      () => {
        this.setState({ render: !this.state.render }, () => {
          this.datatable.draw();
        });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentColumnSet !== this.props.currentColumnSet) {
      this.rerenderTableNewColumnSet();
    }

    if (prevProps.searchValue !== this.props.searchValue) {
      // clearing previous search value (we handle searching with our custom smartSearch)
      this.datatable.search("").draw(true);
    }

    if (!_.isEqual(prevProps.filterOptions, this.props.filterOptions)) {
      // updating ajax and reloading when applying filters
      this.datatable.ajax
        .url(
          `${baseURL}/datatables/pricetool/product${createQueryParamsFromOptions(
            this.props.filterOptions
          )}`
        )
        .load();
    }

    // updating datatable as ref object changes
    this.datatable = this.dataTablesRef.current.dataTable;
  }

  async componentDidMount() {
    let shouldDraw = true;

    while (this.dataTablesRef === null) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // waiting for datatables
    }

    this.datatable = this.dataTablesRef.current.dataTable;

    if (this.props.searchValue) {
      this.datatable.search(this.props.searchValue); // run initial search if given
    }

    if (this.props.match.params.family) {
      const familySearch = this.props.match.params.family;
      const familyColumn = "product_families_view.families";
      document.getElementById(`header_search_${familyColumn}`).value =
        familySearch;
      this.datatable.column(`${familyColumn}:name`).search(familySearch);
    }

    if (this.props.currentColumnSet) {
      this.rerenderTableNewColumnSet();
    }

    if (shouldDraw) this.datatable.draw();
  }

  rowHasChildren = (row) => {
    return row.inventory && row.inventory.length > 0;
  };

  formatNestedRows = (d) => {
    const columnsToDisplay = [
      "supply.sort",
      "supply.upc",
      "supply.supply_asin",
      "supply_families_view.families",
      "inventory.part_number",
      "inventory.inventory_id",
      "supply.supply_title",
      "supply.restock_status_ftp",
      "supply.dtb1",
      "supply.dtb2",
      "supply.dtb3",
      "supply.dtb4",
      "supply.dtb5",
      "supply.dtb6",
      "supply.dtb7",
      "supply.dtb8",
      "supply.sup_lin",
      "inventory.inv",
      "supply.dmg_inv_display",
      "supply.dau_inv",
      "supply.dist_inv",
      "supply.house",
      "inventory.add_date",
      "inventory.sub_date",
      "inventory.inventory_shelf",
      "supply.listed",
      "supply.ono",
      "inventory.avcg",
      "supply.studio",
      "supply.walsku",
      "supply.ai",
      "supply.alliance",
      "supply.discontinued_date",
      "supply.dealsareus",
      "supply.amazon",
      "supply.ccvideo",
      "supply.deepdiscount",
      "supply.blowitebay",
      "supply.importcds",
      "supply.walmart",
      "supply.wowhd",
      "supply.inetvideo",
      "supply.rarewaves",
      "supply.target_store",
      "supply.vei",
      "supply.wildcard1",
      "supply.wildcard1_inv",
      "supply.wildcard2",
      "supply.wildcard2_inv",
      "supply.mila",
      "supply.mila_inv",
      "supply.discount_entertainment",
      "supply.discount_entertainment_inv",
      "supply.gruv",
      "supply.gruv_inv",
      "supply.ebay_itemid",
      "inventory.prod_list_type",
    ];

    if (!d.inventory || d.inventory.length === 0) {
      return null;
    }

    // Create table with dynamic headers
    let tableHtmlString = `<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;" width="1500px">`;

    // Generate header row
    tableHtmlString += "<tr>";
    columnsToDisplay.forEach((col) => {
      if (col === "supply.supply_image")
        return (tableHtmlString += `<td></td>`);

      console.log(col);

      const column = masterInventoryColumnSet.find(
        (colDef) => colDef.data === col
      );

      tableHtmlString += `<td>${column.title}</td>`;
    });
    tableHtmlString += "</tr>";

    // Generate data rows
    d.inventory
      .sort((a, b) => Number(a.supply.sort) - Number(b.supply.sort))
      .forEach((inventory) => {
        tableHtmlString +=
          "<tr>" +
          columnsToDisplay
            .map((col) => {
              const [table, column] = col.split(".");
              const columnDef = masterInventoryColumnSet.find(
                (colDef) => colDef.data === col
              );

              if (!columnDef.render)
                return `<td>${inventory[table][column] || ""}</td>`;

              return `<td>${columnDef.render(
                inventory[table][column],
                null,
                inventory
              )}</td>`;
            })
            .join("") +
          "</tr>";
      });

    tableHtmlString += "</table>";
    return tableHtmlString;
  };

  handleAppendMessage = (rows, status, errorFields) => {
    this.setState({
      modalName: "AppendMessage",
      selectedRowsArray: rows.toArray(),
      appendStatus: status,
      appendErrors: errorFields,
    });
  };

  handleNullFilter = (columnName, isFiltered, dt) => {
    this.setState(
      {
        dtProductColumns: this.state.dtProductColumns.map((col) =>
          col.data === columnName ? { ...col, filterNulls: isFiltered } : col
        ),
      },
      () => {
        dt.draw();
      }
    );
  };

  showAppendForm = (tableNames, columnNames) => {
    this.setState({
      modalName: "AppendForm",
      appendColumnNames: columnNames,
      appendTableNames: tableNames,
    });
  };

  submitAppendForm = (appendDateFlag, formData) => {
    let editor;
    if (!appendDateFlag) {
      editor = appendValue(
        this.datatable,
        this.dataTablesRef.current.editor,
        this.state.appendTableNames,
        this.state.appendColumnNames.map((col) => col.name),
        formData
      );
    } else {
      editor = appendUnixDate(
        this.datatable,
        this.dataTablesRef.current.editor,
        this.state.appendTableNames,
        this.state.appendColumnNames.map((col) => col.name)
      );
    }

    const selectedRowsApi = this.datatable.rows({ selected: true }).data();

    editor &&
      editor.submit(
        () => {
          this.handleAppendMessage(selectedRowsApi, true);
        },
        (errObj) => {
          this.handleAppendMessage(selectedRowsApi, false, errObj.fieldErrors);
        }
      );
  };

  // search field onChange event handler
  onChangeSearch = (e) => {
    const { value } = e.target;
    const searchValue = value;

    this.setState({ searchValue });

    // waits for user to stop typing for 2 seconds to run search
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
      this.datatable.draw();
    }, 2000);
    this.displayLoading();
  };

  handleSubmit = (e) => {
    // if user has pressed the enter key, trigger search immediately
    if (e.key === "Enter") {
      clearTimeout(this.searchDelay);
      this.displayLoading();
      this.datatable.draw();
    }
  };

  // changes the state of the loading icon on the application after 1 second of inactivity from the user
  displayLoading = debounce(() => {
    this.setState({ isLoading: true });
  }, 1000);

  customButtons = () => {
    return [
      {
        text: "<span title='Refresh Table'>Refresh</span>",
        action: (e, dt, node, config) => {
          dt.ajax.reload();
        },
      },
      {
        text: "<span title='Clears the filters, searches, and sorts of your column only'>Reset Columns</span>",
        action: (e, dt, node, config) => {
          this.resetColumns(dt);
        },
      },
      {
        text: "<select title='Select the input mode for the Amazon Price Calculator. \n\nCogs-Only: Only asks for the Cost of the Good.' style='font-size: 10px;width:35px;height:100%;' id='selectamzcalcfillmode'><option>Cogs-Only</option></select> <select style='font-size: 10px;width:35px;height:100%;' id='selectamzcalc2'><option>None</option><option>Single</option><option>Multi</option></select>",
        action: function (e, dt, node, config) {},
      },
      {
        extend: "selectedSingle",
        text: "<span title='Amazon Price Calculator'>Amz Price Calc</span>",
        action: async (e, dt, node, config) => {
          const fees = await amazonCalculator(dt);

          console.log(fees);

          if (fees) {
            this.props.appendOutputLog(fees);
          }
        },
      },
      {
        extend: "collection",
        text: "<span title='Analyze Item'>Analysis</span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Copy selected UPCs'>MSKUs</span>",
            action: (e, dt, node, config) => {
              const data = dt.rows({ selected: true }).data();
              let mskus = [];

              data.each((row) => {
                mskus.push(row["product"]["msku"]);
              });

              if (mskus.length) {
                this.setState({
                  msg: mskus.join(","),
                  isOpenMessageModal: true,
                });
              } else {
                alert("Please select row(s)");
              }
            },
          },
          {
            text: "<span title='Sum up the values of a specific column for selected rows.'>Sum Column</span>",
            action: (e, dt, node, config) => {
              const selectedRowsApi = dt.rows({ selected: true }).data();
              this.setState({
                modalName: "SumColumn",
                selectedRowsArray: selectedRowsApi.toArray(),
              });
            },
          },
          {
            text: "<span title='Push the current Next Ship Qty value into the Amz Ship Qty column.'>NSQ to ASQ</span>",
            action: (e, dt, node, config) => {
              const rows = dt.rows({ selected: true });

              const editor = this.dataTablesRef.current.editor;
              editor.edit(rows.indexes(), false);

              rows.every(function () {
                const data = this.data();
                const nextShipQty = data.product.next_amz_ship_qty;
                editor
                  .field("product.amz_ship_qty")
                  .multiSet(data.DT_RowId, nextShipQty);
                return null;
              });

              editor.submit(
                () => {
                  this.props.addNotification({
                    text: `Successfully pushed Next Ship Qty to Amz Ship Qty!`,
                    icon: <Icon color="green" name="checkmark box" />,
                  });
                },
                (errObj) => {
                  console.log(errObj);
                  this.props.addNotification({
                    text: `Error pushing Next Ship Qty to Amz Ship Qty.`,
                    icon: <Icon color="red" name="warning circle" />,
                  });
                }
              );
            },
          },
          {
            text: "<span title='Push the current Yom Ship Rec value into the Next Ship Qty column.'>YSR to NSQ</span>",
            action: (e, dt, node, config) => {
              const rows = dt.rows({ selected: true });

              const editor = this.dataTablesRef.current.editor;
              editor.edit(rows.indexes(), false);

              rows.every(function () {
                const data = this.data();
                const yomShipRec = data.product.yom_ship_rec;
                editor
                  .field("product.next_amz_ship_qty")
                  .multiSet(data.DT_RowId, yomShipRec);
                return null;
              });

              editor.submit(
                () => {
                  this.props.addNotification({
                    text: `Successfully pushed Yom Ship Rec to Next Ship Qty!`,
                    icon: <Icon color="green" name="checkmark box" />,
                  });
                },
                (errObj) => {
                  console.log(errObj);
                  this.props.addNotification({
                    text: `Error pushing Yom Ship Rec to Next Ship Qty.`,
                    icon: <Icon color="red" name="warning circle" />,
                  });
                }
              );
            },
          },
          {
            text: "Auto Margin Script",
            action: (e, dt, node, config) => {
              calcAutoMargin();
            },
          },
          {
            text: "Amazon Orders Script",
            action: (e, dt, node, config) => {
              alert("Not implemented yet");
            },
          },
          {
            text: "ATS Script",
            action: (e, dt, node, config) => {
              dvdlocator
                .post("/invoke-ats")
                .then((res) => {
                  if (res.data.includes("Task already running")) {
                    this.props.addNotification({
                      text: `ATS task already running.`,
                      icon: <Icon color="yellow" name="checkmark box" />,
                    });
                  } else
                    this.props.addNotification({
                      text: `Successfully started ATS task!`,
                      icon: <Icon color="green" name="checkmark box" />,
                    });
                })
                .catch((err) =>
                  this.props.addNotification({
                    text: `Error starting ATS script.`,
                    icon: <Icon color="red" name="warning circle" />,
                  })
                );
            },
          },
          {
            text: "MAVCG Script",
            action: (e, dt, node, config) => {
              dvdlocator
                .post("/invoke-mavcg")
                .then((res) => {
                  if (res.data.includes("Task already running")) {
                    this.props.addNotification({
                      text: `MAVCG task already running.`,
                      icon: <Icon color="green" name="checkmark box" />,
                    });
                  } else
                    this.props.addNotification({
                      text: `Successfully started MAVCG task!`,
                      icon: <Icon color="green" name="checkmark box" />,
                    });
                })
                .catch((err) =>
                  this.props.addNotification({
                    text: `Error starting MAVCG script.`,
                    icon: <Icon color="red" name="warning circle" />,
                  })
                );
            },
          },
          {
            extend: "selected",
            text: "Retire/Unretire",
            action: (e, dt, node, config) => {
              const products = dt.rows({ selected: true });

              const productIds = products
                .data()
                .map((item) => item.product.product_id)
                .toArray();

              let retiring = true;

              products.every(function (i, tableLoop, rowLoop) {
                const data = this.data();
                if (data.product.retired) retiring = false;
                return null;
              });

              dvdlocator
                .post("/products/retire", { productIds, retired: retiring })
                .then(() => {
                  this.props.addNotification({
                    text: `Successfully ${
                      retiring ? "retired" : "unretired"
                    } product(s)!`,
                    icon: <Icon color="green" name="checkmark box" />,
                  });
                  dt.ajax.reload();
                })
                .catch((err) => {
                  console.log(err);
                  this.props.addNotification({
                    text: `There was an error ${
                      retiring ? "retiring" : "unretiring"
                    } the product(s)!`,
                    icon: <Icon color="red" name="warning circle" />,
                  });
                });
            },
          },
          {
            text: "Refresh Analytics Columns",
            action: (e, dt, node, config) => {
              dvdlocator
                .post("/refresh-analytics-db-columns")
                .then((res) => {
                  this.props.addNotification({
                    text: `Successfully started task! Please wait a moment for update.`,
                    icon: <Icon color="green" name="checkmark box" />,
                  });
                })
                .catch((err) =>
                  this.props.addNotification({
                    text: `Error starting task.`,
                    icon: <Icon color="red" name="warning circle" />,
                  })
                );
            },
          },
        ],
        fade: 100,
      },
      {
        extend: "collection",
        text: "<span title='Ware House Actions'>Warehouse</span>",
        autoClose: true,
        buttons: [
          {
            extend: "selected",
            text: "<span title='Amazon Barcode'>Amazon Barcode</span>",
            action: (e, dt, node, config) => {
              const data = dt.rows({ selected: true }).data();

              // reset rowData first
              this.rowData = [];

              if (data.length === 1) {
                const count = prompt(
                  "How many copies of this barcode would you like?",
                  1
                );

                // add count copies of data[0] to this.rowData
                for (let i = 0; i < count; i++) {
                  this.rowData.push(data[0]);
                }
              } else {
                // otherwise add amz_ship_qty amount of each row
                for (let i = 0; i < data.length; i++) {
                  const { amz_ship_qty } = data[i].product;

                  if (amz_ship_qty > 0) {
                    for (let j = 0; j < amz_ship_qty; j++) {
                      this.rowData.push(data[i]);
                    }
                    // if amz_ship_qty empty or 0, just add one copy
                  } else {
                    this.rowData.push(data[i]);
                  }
                }
              }

              this.barCodeType = "amazon";
              this.setState({ modalIsActive: true });
            },
          },
          {
            extend: "selectedSingle",
            text: "<span style='pointer-events: auto !important;' title='Create a duplicate of the selected product'>Duplicate Row</span>",
            action: async (e, dt, node, config) => {
              this.openDuplicateForm();
            },
          },
        ],
        fade: 100,
      },
      {
        extend: "collection",
        text: "<span title='Jons Precious Menu'>Curator</span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Append / Remove from Listory'>Append Listory</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "listory", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Clear Sort Column '>Clear \"Sort\" Column</span>",
            action: (e, dt, node, config) => {
              if (
                window.confirm(
                  "Are you sure you would like to clear the data in the Sort column?"
                )
              ) {
                clearData(
                  dt,
                  this.dataTablesRef.current.editor,
                  ["product"],
                  ["sort"]
                );
              }
            },
          },
        ],
      },
      {
        extend: "collection",
        text: "<span title='Search for Items'>Open</span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Open Families'>Product Families</span>",
            action: (e, dt, node, config) => {
              openFamiliesProduct(dt, this.state.currentColumnSet);
            },
          },
          {
            text: "<span title='Open ItemIDs in Repricer Express'>Repricer</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://dashboard-5.repricer.com/repricer?fai_fca_id%5B%5D=52849&fsf_query=",
                "product",
                "msku",
                ""
              );
            },
          },

          {
            text: "<span title='Open UPC in Amazon Order History' >Order History</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://sellercentral.amazon.com/orders-v3/search?page=1&date-range=last-7&q=",
                "product",
                "msku",
                "&qt=sku"
              );
            },
          },
          {
            text: "<span title='Open UPC in Amazon Orders table' >Orders</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "/datatables/amazon-orders/",
                "product",
                "msku",
                ""
              );
            },
          },
          {
            text: "<span title='Open ASIN in Buy Box' >Buy Box</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://www.amazon.com/dp/",
                "product",
                "product_asin",
                ""
              );
            },
          },
          {
            text: "<span title='Open UPC in Re-Stock / Price' >Re-Stock</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://sellercentral.amazon.com/inventory/view/PRICING?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%7D;search:",
                "product",
                "fnsku",
                ";pagination:1;"
              );
            },
          },
          {
            text: "<span title='Open inventory management for ASIN in Seller Central' >Manage Inventory</span>",
            action: (e, dt, node, config) => {
              dt.rows({ selected: true }).every(function () {
                const rowData = this.data();
                const asin = rowData.product.product_asin;

                if (asin && asin.trim()) {
                  const trimmedAsin = asin.trim();
                  window.open(
                    `https://sellercentral.amazon.com/myinventory/inventory?fulfilledBy=all&page=1&pageSize=10&searchField=all&searchTerm=${trimmedAsin}&sort=sales_desc&status=all`
                  );
                }

                return null;
              });
            },
          },
          {
            text: "<span title='Open UPC in Amazon Pricing table' >Amz Pricing</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "/datatables/amazon-pricing/",
                "product",
                "msku",
                ""
              );
            },
          },
        ],
        fade: 100,
      },
      {
        extend: "collection",
        text: "<span title='Append to Table'>Append</span>",
        buttons: [
          {
            text: "<span title='Append / Remove from Part_Number'>Partnum</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "part_number", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append / Remove from Amazon_Queue'>Amazon Queue</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "amz_queue", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append / Remove from Orders'>Orders</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "orders", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append / Remove from Warehouse Notes'>Wn</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "wn", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append / Remove from Pick Status'>Pick Status</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product"],
                [{ name: "pick_status", type: "string" }]
              );
            },
          },
          {
            text: "<span title='Append/remove from Amazon Queue and Next Ship Qty'>Amazon Queue & Next Ship Qty</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["product", "product"],
                [
                  { name: "amz_queue", type: "string" },
                  { name: "next_amz_ship_qty", type: "number" },
                ]
              );
            },
          },
        ],
        fade: 100,
      },
    ];
  };

  existingColumnSearches() {
    const columns = this.datatable.columns();

    const searches = [];

    columns.every(function () {
      const data = this.dataSrc();
      const search = this.search();

      if (search !== "") {
        searches.push({
          data,
          search,
        });
      }
      return null;
    });

    return searches;
  }

  // handler for opening the barcode modal
  setModalOpen = (val) => {
    this.setState({
      modalIsActive: val,
      labelModalIsActive: val,
      isOpenMessageModal: val,
    });
  };

  // abstracts openLinks function to avoid having to pass a ref to the left sidebar
  openPricetoolLink = (store) => {
    if (this.dataTablesRef.current) {
      openLinks(this.dataTablesRef.current.dataTable, store);
    }
  };

  closeFormModal = () => {
    this.setState({
      modalName: "",
      selectedRowsArray: [],
    });
  };

  openDuplicateForm = () => {
    const dt = this.dataTablesRef.current.dataTable;
    const row = dt.row({ selected: true }).data();

    this.setState({
      productToDuplicate: {
        product_id: row.product.product_id,
        title: row.product.product_title,
        partNumber: row.product.assembly_part_number,
        asin: row.product.product_asin,
        families: row.product_families.map((subRow) => ({
          id: subRow.product_families.id,
          title: subRow.product_families.family,
        })),
      },
      creatingProduct: true,
    });
  };

  handleContextMenu = (e) => {
    if (window.getSelection().toString()) return; // if the user has highlighted text

    e.preventDefault();
    let rowsPreselected = true;

    $("tr").removeClass("contextmenu"); // cleaning other selections

    const dt = this.dataTablesRef.current.dataTable;
    let rows = dt.rows({ selected: true }).data().toArray();

    const clickedRow = $(e.target).closest("tr").first();

    // If no selection, use the right-clicked row
    if (rows.length === 0) {
      rowsPreselected = false;

      const deselectRow = () => {
        clickedRow.removeClass("contextmenu");
      };

      clickedRow.addClass("contextmenu");
      window.addEventListener("click", deselectRow, { once: true });

      rows = dt
        .rows([`#${clickedRow.attr("id")}`])
        .data()
        .toArray();
    }

    this.setState((prev) => ({
      ...prev,
      selectedRowsArray: rows,
      contextMenu: {
        ...prev.contextMenu,
        display: true,
        event: e,
        rowsPreselected: rowsPreselected,
      },
    }));
  };

  toggleTableVisibility = (tableName) => {
    this.setState((prevState) => ({
      [`${tableName}TableOpen`]: !prevState[`${tableName}TableOpen`],
    }));
  };

  closeCreateForm = (skipConfirmation) => {
    if (skipConfirmation) {
      this.setState({ creatingProduct: false });
    } else {
      this.setState({ confirmCloseModalOpen: true });
    }
  };

  handleConfirmClose = () => {
    this.setState({
      creatingProduct: false,
      confirmCloseModalOpen: false,
    });
  };

  handleConfirmCloseCancel = () => {
    this.setState({ confirmCloseModalOpen: false });
  };

  render() {
    const {
      dtEditorFields,
      dtProductColumns,
      modalIsActive,
      labelModalIsActive,
      creatingProduct,
      confirmCloseModalOpen,
    } = this.state;

    return (
      <Fragment>
        <Confirm
          open={confirmCloseModalOpen}
          content="Are you sure you want to close the create form? Any unsaved changes will be lost."
          onCancel={this.handleConfirmCloseCancel}
          onConfirm={this.handleConfirmClose}
        />
        {creatingProduct && (
          <CreateProductWizard
            onClose={this.closeCreateForm}
            productToDuplicate={this.state.productToDuplicate}
          />
        )}
        <ProductContextMenu
          open={this.state.contextMenu.display}
          event={this.state.contextMenu.event}
          selectedRows={this.state.selectedRowsArray}
          rowsPreselected={this.state.contextMenu.rowsPreselected}
          openContextModal={(modalName) =>
            this.setState((prev) => ({
              ...prev,
              contextMenu: { ...prev.contextMenu, modal: modalName },
            }))
          }
          closeContextMenu={() =>
            this.setState((prev) => ({
              contextMenu: {
                ...prev.contextMenu,
                display: false,
                event: null,
              },
            }))
          }
        />
        {this.state.contextMenu.modal === "family" && (
          <FamilyManagement
            open={this.state.contextMenu.modal === "family"}
            close={() => {
              this.dataTablesRef.current.dataTable.ajax.reload();
              this.setState((prev) => ({
                ...prev,
                contextMenu: { ...prev.contextMenu, modal: "" },
              }));
            }}
            loading={this.state.contextMenu.loading}
            initialProducts={this.state.selectedRowsArray}
          />
        )}

        <MessageModal
          open={this.state.isOpenMessageModal}
          msg={this.state.msg}
          setOpen={this.setModalOpen}
          copy={true}
        />
        <ModalSwitch
          closeModal={this.closeFormModal}
          selectedRows={this.state.selectedRowsArray}
          modalName={this.state.modalName}
          appendStatus={this.state.appendStatus}
          appendErrors={this.state.appendErrors}
          columnNames={this.state.appendColumnNames}
          submitAppendForm={this.submitAppendForm}
          mode={"product"}
        />

        <ReactModal
          title=""
          content={
            <Modal.Description>
              {
                <BarcodeGenerator
                  height={50}
                  fontSize={22}
                  textMargin={1}
                  margin={0}
                  barCodeType={this.barCodeType}
                  rowData={this.rowData}
                />
              }
            </Modal.Description>
          }
          isActive={modalIsActive}
          setOpen={this.setModalOpen}
        />
        <ReactModal
          title=""
          content={
            <Modal.Description>
              {<ShelvingLabelGenerator rowData={this.rowData} />}
            </Modal.Description>
          }
          isActive={labelModalIsActive}
          setOpen={this.setModalOpen}
        />

        <DataTablesEditor
          key={this.state.render}
          ref={this.dataTablesRef}
          ajax={{
            url: `${baseURL}/datatables/pricetool/product${createQueryParamsFromOptions(
              this.props.filterOptions
            )}`,
            contentType: "application/json",
            type: "POST",
            data: (d) => {
              this.state.dtProductColumns.forEach((col) => {
                const i = d.columns.findIndex(
                  (column) => column.data === col.data
                );
                if (col.filterNulls) d.columns[i].filterNulls = true;
                if (col.excludeFromGeneralSearch === true)
                  d.columns[i].excludeFromGeneralSearch = true;
              });
              d.columns = d.columns.filter((col) => col.data !== null);
              d.smartSearch = this.props.searchValue;
              return JSON.stringify(d);
            },
          }}
          editorAjax={{
            url: `${baseURL}/datatables/pricetool/product`,
            contentType: "application/json",
            type: "POST",
            data: function (d) {
              return JSON.stringify(d);
            },
          }}
          rowHasChildren={this.rowHasChildren}
          rowExpansion={this.formatNestedRows}
          handleContextMenu={this.handleContextMenu}
          columns={dtProductColumns}
          options={this.datatableOptions}
          fields={dtEditorFields}
          pageLengthButton={true}
          createButton={true}
          customCreate={() => this.setState({ creatingProduct: true })}
          onDraw={() => this.setState({ isLoading: false })}
          // customEdit={this.openEditForm}
          editButton={true}
          removeButton={true}
          colVis={false}
          exportButton={true}
          customButtons={this.customButtons}
          columnSearch={true}
          handleNullFilter={this.handleNullFilter}
          storeColumnIndex={this.storeColumnIndex}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (notification) => dispatch(addNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductTable);
