import React, { Fragment } from "react";
import { Modal, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactModal from "../../utilities/ReactModal";
import $ from "jquery";
import { openLinks } from "./buttonHandlers/buttonHandlers";
import {
  handleFTP,
  calcSelectedDTBs,
  calcAllDTBs,
  updateONO,
  updateDamageInventory,
} from "./buttonHandlers/analysis";
import FamilyManagement from "./ContextMenu/Menus/FamilyManagementInventory/FamilyManagement";
import { openFamiliesSupply, openInLink } from "./buttonHandlers/openData";
import { appendUnixDate } from "../_genericButtonHandlers/appendUnixDate";
import { appendValue, clearData } from "./buttonHandlers/appendCol";
import { directiveBuilder } from "./buttonHandlers/directiveBuilder/directiveBuilder";
import { addNotification } from "../../../redux/actions/notificationActions";
import { masterProductColumnSet } from "./ptProductColumns";
import {
  getViewableInventoryColumns,
  masterInventoryColumnSet,
} from "./ptInventoryColumns";
import DataTablesEditor from "../DatatablesEditor/DataTablesEditor";
import BarcodeGenerator from "../../utilities/BarcodeGenerator";
import dvdlocator, { baseURL } from "../../../apis/dvdlocator";
import ModalSwitch from "./buttonHandlers/modals/ModalSwitch";
import MessageModal from "../../MessageModal";
import ShelvingLabelGenerator from "../../utilities/ShelvingLabelGenerator";
import { renderDate } from "../../formatting/dateFormat";
import { attachDuplicateCheckbox } from "./buttonHandlers/warehouse/duplicateRow";
import { AdjustInventory } from "./ContextMenu/Menus/InventoryAdjustment/AdjustInventory";
import InventoryContextMenu from "./ContextMenu/InventoryContextMenu";

class InventoryTable extends React.Component {
  constructor(props) {
    super(props);

    this.searchDelay = null;
    this.barCodeType = null;
    this.rowData = [];
    this.datatable = null;

    this.datatableOptions = {
      dom: "Brtip", // https://datatables.net/reference/option/dom
      processing: true,
      serverSide: true,
      select: {
        selector: "td:not(:first-child)",
      },
      paging: true,
      pageLength: 100,
      lengthChange: true,
      autoWidth: true,
      scrollY: this.props.tableHeight,
      scrollX: true,
      scrollCollapse: true,

      colReorder: { realtime: false },
      deferRender: true,
      deferLoading: true,
      order: [[1, "asc"]],

      lengthMenu: [25, 50, 100, 300, 500, 1000],
    };

    this.state = {
      modalName: "",
      selectedRowsArray: [],
      appendStatus: false,
      appendErrors: [],
      appendColumnNames: [],
      appendTableNames: [],
      isLoading: false,
      barcodeModalIsActive: false,
      msg: "",
      slotLabelModalIsActive: false,
      currentColumnSet: null,
      isOpenMessageModal: false,
      contextMenu: {
        display: false,
        event: null,
        rowsPreselected: false,
        modal: "",
        products: [],
        loading: false,
      },

      dtInventoryColumns: getViewableInventoryColumns(),
      dtEditorFields: masterInventoryColumnSet,

      outputLog: [],
      outputLogClearedTimestamp: null,

      render: true,
    };

    this.dataTablesRef = React.createRef();
  }

  resetColumns(dt) {
    const url = window.location.href;
    // Special case- if viewing a collection, must keep the product family search filter
    const keepFamilySearch = url.includes("/family/");

    const searchInputs = document.querySelectorAll(".header_search_input_dt");
    const nullFilters = document.querySelectorAll(".header_checkbox_input_dt");

    searchInputs.forEach((element) => (element.value = ""));
    nullFilters.forEach((element) => (element.checked = false));

    // Setting all null filters to false (Control)
    this.setState({
      dtInventoryColumns: this.state.dtInventoryColumns.map((col) => ({
        ...col,
        filterNulls: false,
      })),
    });

    if (!keepFamilySearch) return dt.columns().search("").draw();

    const familySearch = this.props.match.params.family;
    dt.columns()
      .search("")
      .column("supply_families_view.families:name")
      .search(familySearch)
      .draw();
  }

  clearOutputLog = () => {
    this.setState((prev) => ({
      outputLogClearedTimestamp: renderDate(Math.floor(Date.now() / 1000)),
      outputLog: prev.outputLog.filter(
        (_, i) => i === prev.outputLog.length - 1
      ),
    }));
  };

  rerenderTableNewColumnSet() {
    const existingSearches = this.existingColumnSearches();

    let viewableColumns = getViewableInventoryColumns(
      this.props.currentColumnSetColumns
    );

    viewableColumns.forEach((column) => {
      const match = existingSearches.find((search) => {
        return search.data === column.data;
      });

      if (match) {
        column.search = {
          value: match.search,
        };
      }
    });

    // forces datatable to re-render with updated columns
    this.setState(
      {
        dtInventoryColumns: viewableColumns,
      },
      () => {
        this.setState({ render: !this.state.render }, () => {
          this.datatable.draw();
        });
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentColumnSet !== this.props.currentColumnSet) {
      this.rerenderTableNewColumnSet();
    }

    if (prevProps.searchValue !== this.props.searchValue) {
      // clearing previous search value (we handle searching with our custom smartSearch)
      this.datatable.search("").draw(true);
    }

    // updating datatable as ref object changes
    this.datatable = this.dataTablesRef.current.dataTable;
  }

  async componentDidMount() {
    let shouldDraw = true;

    while (this.dataTablesRef === null) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // waiting for datatables
    }

    this.datatable = this.dataTablesRef.current.dataTable;

    if (this.props.searchValue) {
      this.datatable.search(this.props.searchValue); // run initial search if given
    }

    if (this.props.match.params.family) {
      const familySearch = this.props.match.params.family;
      const familyColumn = "supply_families_view.families";
      document.getElementById(`header_search_${familyColumn}`).value =
        familySearch;
      this.datatable.column(`${familyColumn}:name`).search(familySearch);
    }

    if (this.props.currentColumnSet) {
      this.rerenderTableNewColumnSet();
    }

    if (shouldDraw) this.datatable.draw();
  }

  rowHasChildren = (row) => {
    return row.product && row.product.length > 0;
  };

  formatNestedRows = (d) => {
    const columnsToDisplay = [
      "product.sort",
      "product.msku",
      "product.product_asin",
      "product.status",
      "product_families_view.families",
      "product.assembly_part_number",
      "product.product_image",
      "product.product_title",
      "product_listings.total_sales",
      "product_listings.last_non_cancelled_order_timestamp",
      "product.buy_box_markup_display",
      "product.buy_box_price",
      "product.buy_box_info",
      "product_listings.price",
      "product.an",
      "product.product_category",
      "product.sales_rank",
      "product.yom_purchase_rec",
      "product.master_analyst_inv",
      "product.ats",
      "product.ats_last_increase_timestamp",
      "product_listings.30_day_profit",
      "product_listings.365_day_profit",
      "product_listings.90_day_profit",
      "product_listings.30_day_profit",
      "product_listings.7_day_profit",
      "product.ship_stock",
      "product.stock",
      "product.yom_ship_rec_holiday",
      "product.yom_ship_rec",
      "product.amz_recommended_ship_quantity",
      "product.yom_ship_rec_amz_ship_rec_diff",
      "product.30_day_restock_in_stock_count",
      "product.restock_status_calculated",
      "product_listings.merchant_available",
      "product_listings.purchasable_stock",
      "product.master_avg_cost_of_goods",
      "product.master_avg_cogs_ninety_day",
      "product.7_day_sales",
      "product.one_year_lowest_cost",
      "product.replacement_cost",
      "product.replacement_cost_dist",
      "product.cogs",
      "product.ysr_nss_rec",
      "product.analytics_db_column_1",
      "product.analytics_db_column_2",
      "product.analytics_db_column_3",
      "product.analytics_db_column_4",
      "product.profit",
      "product.margin",
      "product.margin_30_pricing",
      "product.margin_15_pricing",
      "product.margin_0_pricing",
      "product.cogs_update_timestamp",
      "product.next_amz_ship_qty",
      "product.amz_ship_qty",
      "product.amz_queue",
      "product.pick_status",
      "product.fba_sell_through",
      "product.alert",
      "product_media_types_view.media_types",
      "product.component_count",
      "product.yom_handling_cost_adjustment",
      "product.packaged_weight_ounces",
      "product.yom_handling_cost_adjustment",
      "product.orders",
      "product.shelf",
      "product.wn",
      "product.wwo",
      "product.proceeds",
      "product_listings.open_date",
      "product.retired",
      "product.retired_timestamp",
      "product.weeks_until_depleted",
      "product.amazon_stock_weeks_until_depleted",
      "product.master_quantity_on_order",
      "product.roi",
      "product.last_ninety_day_avg_cogs",
      "product.latest_timestamp",
      "product.30_day_sales",
      "product.type",
      "product.genre",
      "product.card_number",
      "product.ship_weight",
      "product.fnsku",
      "product.part_number",
      "product.listory",
      "product.prod_list_type",
      "product.inv_age_271_to_365",
      "product_profit_projection_view.yom_ship_rec_potential_profit",
      "product.fba_latest_shipment_quantity",
      "product.90_day_sales",
    ];

    if (!d.product || d.product.length === 0) {
      return null;
    }

    // Create table with dynamic headers
    let tableHtmlString = `<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;" width="1500px">`;

    // Generate header row
    tableHtmlString += "<tr>";
    columnsToDisplay.forEach((col) => {
      if (col === "product.product_image")
        return (tableHtmlString += `<td></td>`);

      const column = masterProductColumnSet.find(
        (colDef) => colDef.data === col
      );

      if (!column) {
        tableHtmlString += `<td>${col}</td>`;
      } else {
        tableHtmlString += `<td>${column.title}</td>`;
      }
    });
    tableHtmlString += "</tr>";

    // Generate data rows
    d.product
      .sort((a, b) => Number(a.product.sort) - Number(b.product.sort))
      .forEach((product) => {
        tableHtmlString +=
          "<tr>" +
          columnsToDisplay
            .map((col) => {
              const [table, column] = col.split(".");
              const columnDef = masterProductColumnSet.find(
                (colDef) => colDef.data === col
              );

              if (!columnDef || !columnDef.render)
                return `<td>${product[table][column] || ""}</td>`;

              return `<td>${columnDef.render(
                product[table][column],
                null,
                product
              )}</td>`;
            })
            .join("") +
          "</tr>";
      });

    tableHtmlString += "</table>";
    return tableHtmlString;
  };

  handleAppendMessage = (rows, status, errorFields) => {
    this.setState({
      modalName: "AppendMessage",
      selectedRowsArray: rows.toArray(),
      appendStatus: status,
      appendErrors: errorFields,
    });
  };

  handleNullFilter = (columnName, isFiltered, dt) => {
    this.setState(
      {
        dtInventoryColumns: this.state.dtInventoryColumns.map((col) =>
          col.data === columnName ? { ...col, filterNulls: isFiltered } : col
        ),
      },
      () => {
        dt.draw();
      }
    );
  };

  showAppendForm = (tableNames, columnNames) => {
    this.setState({
      modalName: "AppendForm",
      appendColumnNames: columnNames,
      appendTableNames: tableNames,
    });
  };

  submitAppendForm = (appendDateFlag, formData) => {
    let editor;
    if (!appendDateFlag) {
      editor = appendValue(
        this.datatable,
        this.dataTablesRef.current.editor,
        this.state.appendTableNames,
        this.state.appendColumnNames.map((col) => col.name),
        formData
      );
    } else {
      editor = appendUnixDate(
        this.datatable,
        this.dataTablesRef.current.editor,
        this.state.appendTableNames,
        this.state.appendColumnNames.map((col) => col.name)
      );
    }

    const selectedRowsApi = this.datatable.rows({ selected: true }).data();

    editor &&
      editor.submit(
        () => {
          this.handleAppendMessage(selectedRowsApi, true);
        },
        (errObj) => {
          this.handleAppendMessage(selectedRowsApi, false, errObj.fieldErrors);
        }
      );
  };

  customButtons = () => {
    return [
      {
        text: "<span title='Refresh Table'>Refresh</span>",
        action: (e, dt, node, config) => {
          dt.ajax.reload();
        },
      },
      {
        text: "<span title='Clears the filters, searches, and sorts of your column only'>Reset Columns</span>",
        action: (e, dt, node, config) => {
          this.resetColumns(dt);
        },
      },
      {
        text: "<select style='font-size: 10px;width:30px;height:100%;' id='housedir'><option>H</option> </select><select style='font-size: 10px;width:50px;height:100%;' class='selectstore' ><option>Alliance</option><option>Amazon</option><option>AmazonCa</option><option>AmazonFBA</option><option>AmazonUK</option><option>Barnes&Noble</option><option>BestBuy</option><option>Blowit</option><option>Blowit Ebay</option><option>BookDepository</option><option>BullMoose</option><option>CCVideo</option><option>DealsAreUs</option><option>DeepDiscount</option><option>Discount Entertainment</option><option>Ebay</option><option>Gruv</option><option>ImportCDs</option><option>InetVideo</option><option>Mila</option><option>PBS</option><option>Rarewaves</option><option>Rightstuff</option><option>Scholastic</option><option>Target</option><option>UNV Amazon</option><option>UNV Ebay</option><option>UNV Half</option><option>VEI</option><option>Walmart</option><option>Wildcard1</option><option>Wildcard2</option><option>Wildcard3</option><option>Wildcard4</option><option>WowHD</option><option>UA11</option></select><input type='text' id='initials' placeholder='Init.' style='min-width:1px;font-size:9px;' size='1'>",
        action: function (e, dt, node, config) {},
      },
      {
        text: "<span title='Create Directive'>Drctv</span>",
        action: (e, dt, node, config) => {
          const ret = directiveBuilder(dt);
          if (ret) {
            this.props.appendOutputLog(ret);
          }
        },
      },
      {
        extend: "collection",
        text: "<span title=''>Supplier</span>",
        autoClose: true,
        buttons: [
          {
            text: "Selected DTBs",
            action: (e, dt, node, config) => {
              calcSelectedDTBs(dt);
            },
          },
          {
            text: "All DTBs",
            action: (e, dt, node, config) => {
              const accepted = window.confirm(
                `Are you sure you want to run the "All DTBs" task? It will reset all DTBs before running.`
              );

              if (!accepted) return;
              calcAllDTBs();
            },
          },
          {
            text: "All FTP",
            action: (e, dt, node, config) => {
              handleFTP({ alliance: true, dealsareus: true });
            },
          },
          {
            text: "Alliance FTP",
            action: (e, dt, node, config) => {
              handleFTP({ alliance: true });
            },
          },
          {
            text: "DealsAreUs FTP",
            action: (e, dt, node, config) => {
              handleFTP({ dealsareus: true });
            },
          },

          {
            text: "<span title='Upload CSV pricing/inventory data from limited set of suppliers'>Upload Supplier Data</span>",
            action: (e, dt, node, config) => {
              this.setState({
                modalName: "UploadSupplierData",
              });
            },
          },
          {
            text: "<span title='Clear out prices for selected suppliers'>Clear Supplier Data</span>",
            action: (e, dt, node, config) => {
              this.setState({
                modalName: "ClearSupplierData",
              });
            },
          },
          {
            text: "Update ONO",
            action: (e, dt, node, config) => {
              updateONO();
            },
          },
        ],
      },
      {
        extend: "collection",
        text: "<span title=''>Work Orders </span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Used by an Analyst to mark up a product with a work order for warehouse personnel. Repair means we are going to fix the product in house, Return means it will be going back to the supplier.'>Damage Work Order</span>",
            action: (e, dt, node, config) => {
              const selectedRowsApi = dt.rows({ selected: true }).data();
              this.setState({
                modalName: "DamageWorkOrder",
                selectedRowsArray: selectedRowsApi.toArray(),
              });
            },
          },
          {
            text: "<span title='Used when making manual edits to the Damage Datatable - updates the DMG_INV column to reflect these edits.'>Manual Update DMG Inv</span>",
            action: (e, dt, node, config) => {
              updateDamageInventory();
            },
          },
        ],
      },
      {
        extend: "collection",
        text: "<span title='Analyze Item'>Analysis</span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Copy selected UPCs'>UPCs</span>",
            action: (e, dt, node, config) => {
              const data = dt.rows({ selected: true }).data();
              let upcs = [];

              data.each((row) => {
                upcs.push(row["supply"]["upc"]);
              });

              if (upcs.length) {
                this.setState({
                  msg: upcs.join(","),
                  isOpenMessageModal: true,
                });
              } else {
                alert("Please select row(s)");
              }
            },
          },
          {
            text: "<span title='Sum up the values of a specific column for selected rows.'>Sum Column</span>",
            action: (e, dt, node, config) => {
              const selectedRowsApi = dt.rows({ selected: true }).data();
              this.setState({
                modalName: "SumColumn",
                selectedRowsArray: selectedRowsApi.toArray(),
              });
            },
          },
          {
            text: "Refresh Analytics Columns",
            action: (e, dt, node, config) => {
              dvdlocator
                .post("/refresh-analytics-db-columns")
                .then((res) => {
                  this.props.addNotification({
                    text: `Successfully started task! Please wait a moment for update.`,
                    icon: <Icon color="green" name="checkmark box" />,
                  });
                })
                .catch((err) =>
                  this.props.addNotification({
                    text: `Error starting task.`,
                    icon: <Icon color="red" name="warning circle" />,
                  })
                );
            },
          },
        ],
        fade: 100,
      },
      {
        extend: "collection",
        text: "<span title='Ware House Actions'>Warehouse</span>",
        autoClose: true,
        buttons: [
          {
            extend: "selected",
            text: "<span title='Bar Code'>UPC Barcode</span>",
            action: (e, dt, node, config) => {
              const data = dt.rows({ selected: true }).data();

              // reset rowData first
              this.rowData = [];

              if (data.length === 1) {
                const count = prompt(
                  "How many copies of this barcode would you like?",
                  1
                );
                // add count copies of data[0] to this.rowData
                for (let i = 0; i < count; i++) {
                  this.rowData.push(data[0]);
                }
              } else {
                this.rowData = data;
              }

              this.barCodeType = "upc";
              this.setState({ barcodeModalIsActive: true });
            },
          },
          {
            extend: "selected",
            text: "<span title='Generate warehouse shelving slot label for selected row(s)'>Generate Slot Label</span>",
            action: (e, dt, node, config) => {
              const data = dt.rows({ selected: true }).data();
              // reset rowData first
              this.rowData = [];

              if (data.length === 1) {
                const count = prompt(
                  "How many copies of this label would you like?",
                  1
                );
                // add count copies of data[0] to this.rowData
                for (let i = 0; i < count; i++) {
                  this.rowData.push(data[0]);
                }
              } else {
                this.rowData = data;
              }
              this.setState({ slotLabelModalIsActive: true });
            },
          },
          {
            text: "Generate Shelving List",
            action: (e, dt, node, config) => {
              this.setState({ modalName: "ShelvingList" });
            },
          },
          {
            extend: "selectedSingle",
            text: "<span style='pointer-events: auto !important;' title='Duplicate the following fields into a new row: ASIN, Title, Ship Wt, Card#, Member of, WWO, WN, Shelf, Genre'>Duplicate Row</span>",
            action: async (e, dt, node, config) => {
              this.openDuplicateForm();
            },
          },
        ],
        fade: 100,
      },
      {
        extend: "collection",
        text: "<span title='Jons Precious Menu'>Curator</span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Clear Sort Column '>Clear \"Sort\" Column</span>",
            action: (e, dt, node, config) => {
              if (
                window.confirm(
                  "Are you sure you would like to clear the data in the Sort column?"
                )
              ) {
                clearData(
                  dt,
                  this.dataTablesRef.current.editor,
                  ["supply"],
                  ["sort"]
                );
              }
            },
          },
        ],
      },
      {
        extend: "collection",
        text: "<span title='Search for Items'>Open</span>",
        autoClose: true,
        buttons: [
          {
            text: "<span title='Open Families'>Product Families</span>",
            action: (e, dt, node, config) => {
              openFamiliesSupply(dt, this.state.currentColumnSet);
            },
          },

          {
            text: "<span title='Open UPC in Gmail' >Gmail</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://mail.google.com/mail/u/0/#search/",
                "supply",
                "upc",
                ""
              );
            },
          },
          {
            text: "<span title='Open ASIN in Buy Box' >Buy Box</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "https://www.amazon.com/dp/",
                "product",
                "asin",
                ""
              );
            },
          },
          {
            text: "<span title='Open UPC in House Ordered table' >House</span>",
            action: (e, dt, node, config) => {
              openInLink(dt, "/datatables/house/", "supply", "upc", "");
            },
          },
          {
            text: "<span title='Open UPC in House Received table' >House Received</span>",
            action: (e, dt, node, config) => {
              openInLink(
                dt,
                "/datatables/house-received/",
                "supply",
                "upc",
                ""
              );
            },
          },
          {
            text: "<span title='Open UPC in Damaged table' >Damages</span>",
            action: (e, dt, node, config) => {
              openInLink(dt, "/datatables/damage/", "supply", "upc", "");
            },
          },
        ],
        fade: 100,
      },
      {
        extend: "collection",
        text: "<span title='Append to Table'>Append</span>",
        buttons: [
          {
            text: "<span title='Append / Remove from Listed'>Listed</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["supply"],
                [{ name: "listed", type: "string" }]
              );
            },
          },

          {
            text: "<span title='Append / Remove from House'>House</span>",
            action: (e, dt, node, config) => {
              this.showAppendForm(
                ["supply"],
                [{ name: "house", type: "string" }]
              );
            },
          },
        ],
        fade: 100,
      },
    ];
  };

  existingColumnSearches() {
    const columns = this.datatable.columns();

    const searches = [];

    columns.every(function () {
      const data = this.dataSrc();
      const search = this.search();

      if (search !== "") {
        searches.push({
          data,
          search,
        });
      }
      return null;
    });

    return searches;
  }

  // handler for opening the barcode modal
  setModalOpen = (val) => {
    this.setState({
      barcodeModalIsActive: val,
      slotLabelModalIsActive: val,
      isOpenMessageModal: val,
    });
  };

  // abstracts openLinks function to avoid having to pass a ref to the left sidebar
  openPricetoolLink = (store) => {
    if (this.dataTablesRef.current) {
      openLinks(this.dataTablesRef.current.dataTable, store);
    }
  };

  closeFormModal = () => {
    this.setState({
      modalName: "",
      selectedRowsArray: [],
    });
  };

  openDuplicateForm = () => {
    const fieldsToDuplicate = [
      "supply.supply_title",
      "supply.supply_asin",
      "supply.supply_title",
      "supply.inventory_id",
      "inventory.inventory_shelf",
      "inventory.inventory_id",
    ];

    const dt = this.dataTablesRef.current.dataTable;
    const editor = this.dataTablesRef.current.editor;
    const row = dt.row({ selected: true }).data();

    editor
      .edit(dt.row({ selected: true }).nodes(), {
        title: "Duplicate supply unit",
        buttons: [
          {
            label: "Create",
            fn: function () {
              this.submit();
            },
            className: "primary",
          },
        ],
      })
      .mode("create");

    // Get all field names from the editor's init configuration
    const allFields = Object.keys(editor.s.fields);

    // Clear all fields except those in fieldsToDuplicate
    allFields.forEach((fieldName) => {
      if (!fieldsToDuplicate.includes(fieldName)) {
        editor.set(fieldName, "");
      }
    });

    editor.set("supply.supply_title", `DUPLICATE - ${row.supply.supply_title}`);

    attachDuplicateCheckbox(editor, dt);
  };

  handleContextMenu = (e) => {
    if (window.getSelection().toString()) return; // if the user has highlighted text

    e.preventDefault();
    let rowsPreselected = true;

    $("tr").removeClass("contextmenu"); // cleaning other selections

    const dt = this.dataTablesRef.current.dataTable;
    let rows = dt.rows({ selected: true }).data().toArray();

    const clickedRow = $(e.target).closest("tr").first();

    // If no selection, use the right-clicked row
    if (rows.length === 0) {
      rowsPreselected = false;

      const deselectRow = () => {
        clickedRow.removeClass("contextmenu");
      };

      clickedRow.addClass("contextmenu");
      window.addEventListener("click", deselectRow, { once: true });

      rows = dt
        .rows([`#${clickedRow.attr("id")}`])
        .data()
        .toArray();
    }

    this.setState((prev) => ({
      ...prev,
      selectedRowsArray: rows,
      contextMenu: {
        ...prev.contextMenu,
        display: true,
        event: e,
        rowsPreselected: rowsPreselected,
      },
    }));
  };

  toggleTableVisibility = (tableName) => {
    this.setState((prevState) => ({
      [`${tableName}TableOpen`]: !prevState[`${tableName}TableOpen`],
    }));
  };

  render() {
    const {
      dtEditorFields,
      dtInventoryColumns,
      barcodeModalIsActive,
      slotLabelModalIsActive,
    } = this.state;

    return (
      <Fragment>
        <InventoryContextMenu
          open={this.state.contextMenu.display}
          event={this.state.contextMenu.event}
          selectedRows={this.state.selectedRowsArray}
          rowsPreselected={this.state.contextMenu.rowsPreselected}
          openContextModal={(modalName) =>
            this.setState((prev) => ({
              ...prev,
              contextMenu: { ...prev.contextMenu, modal: modalName },
            }))
          }
          closeContextMenu={() =>
            this.setState((prev) => ({
              contextMenu: {
                ...prev.contextMenu,
                display: false,
                event: null,
              },
            }))
          }
        />
        {this.state.contextMenu.modal === "family" && (
          <FamilyManagement
            open={this.state.contextMenu.modal === "family"}
            close={() => {
              this.dataTablesRef.current.dataTable.ajax.reload();
              this.setState((prev) => ({
                ...prev,
                contextMenu: { ...prev.contextMenu, modal: "" },
              }));
            }}
            loading={this.state.contextMenu.loading}
            initialUnits={this.state.selectedRowsArray}
          />
        )}
        {this.state.contextMenu.modal === "inventoryAdjustment" && (
          <AdjustInventory
            datatable={this.dataTablesRef.current.dataTable}
            open={this.state.contextMenu.modal === "inventoryAdjustment"}
            close={() => {
              this.setState((prev) => ({
                ...prev,
                contextMenu: { ...prev.contextMenu, modal: "" },
              }));
            }}
            userId={this.props.auth.userId}
            initialUnits={this.state.selectedRowsArray}
          />
        )}

        <MessageModal
          open={this.state.isOpenMessageModal}
          msg={this.state.msg}
          setOpen={this.setModalOpen}
          type="upc"
          copy={true}
        />
        <ModalSwitch
          closeModal={this.closeFormModal}
          selectedRows={this.state.selectedRowsArray}
          modalName={this.state.modalName}
          appendStatus={this.state.appendStatus}
          appendErrors={this.state.appendErrors}
          columnNames={this.state.appendColumnNames}
          submitAppendForm={this.submitAppendForm}
          mode={"inventory"}
        />

        <ReactModal
          title=""
          content={
            <Modal.Description>
              {
                <BarcodeGenerator
                  height={50}
                  fontSize={22}
                  textMargin={1}
                  margin={0}
                  barCodeType={this.barCodeType}
                  rowData={this.rowData}
                />
              }
            </Modal.Description>
          }
          isActive={barcodeModalIsActive}
          setOpen={this.setModalOpen}
        />
        <ReactModal
          title=""
          content={
            <Modal.Description>
              {<ShelvingLabelGenerator rowData={this.rowData} />}
            </Modal.Description>
          }
          isActive={slotLabelModalIsActive}
          setOpen={this.setModalOpen}
        />

        <DataTablesEditor
          key={this.state.render}
          ref={this.dataTablesRef}
          ajax={{
            url: `${baseURL}/datatables/pricetool/inventory`,
            contentType: "application/json",
            type: "POST",
            data: (d) => {
              this.state.dtInventoryColumns.forEach((col) => {
                const i = d.columns.findIndex(
                  (column) => column.data === col.data
                );
                if (col.filterNulls) d.columns[i].filterNulls = true;
                if (col.excludeFromGeneralSearch === true)
                  d.columns[i].excludeFromGeneralSearch = true;
              });
              d.columns = d.columns.filter((col) => col.data !== null);
              d.smartSearch = this.props.searchValue;
              return JSON.stringify(d);
            },
          }}
          editorAjax={{
            url: `${baseURL}/datatables/pricetool/inventory`,
            contentType: "application/json",
            type: "POST",
            data: function (d) {
              return JSON.stringify(d);
            },
          }}
          rowHasChildren={this.rowHasChildren}
          rowExpansion={this.formatNestedRows}
          handleContextMenu={this.handleContextMenu}
          columns={dtInventoryColumns}
          options={this.datatableOptions}
          fields={dtEditorFields}
          pageLengthButton={true}
          createButton={true}
          onDraw={() => this.setState({ isLoading: false })}
          editButton={true}
          removeButton={true}
          colVis={false}
          exportButton={true}
          customButtons={this.customButtons}
          columnSearch={true}
          handleNullFilter={this.handleNullFilter}
          storeColumnIndex={this.storeColumnIndex}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNotification: (notification) => dispatch(addNotification(notification)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryTable);
