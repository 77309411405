import React, { useState } from "react";
import {
  Button,
  Header,
  Icon,
  Label,
  List,
  Modal,
  Grid,
  Divider,
} from "semantic-ui-react";
import axios from "../../../../../../apis/dvdlocator";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../../../../redux/actions/notificationActions";
import AddFamilySelect from "./AddFamilySelect";
import DeleteFamilySelect from "./DeleteFamilySelect";
import FamilyPanel from "./FamilyPanel";

const FamilyManagement = ({ open, close, initialProducts }) => {
  const dispatch = useDispatch();
  const [deleteSubmitting, setDeleteSubmitting] = useState(false);
  const [products, setProducts] = useState(initialProducts);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [addingAll, setAddingAll] = useState(false);
  const [deleteSelection, setDeleteSelection] = useState({
    product: null,
    family: null,
  });
  const [deleteHoverId, setDeleteHoverId] = useState(null);
  const [familyHoverId, setFamilyHoverId] = useState(null);
  const [familyToView, setFamilyToView] = useState({});
  const [modal, setModal] = useState("");

  const openFamilyPanel = (family) => {
    setFamilyToView(family);
    setModal("familyPanel");
  };

  const getAllUniqueProductFamilies = () => {
    const families = [];

    products.forEach((product) => {
      product.product_families.forEach((fam) => {
        const id = fam.id;

        if (!families.some((entry) => entry.id === id)) families.push(fam);
      });
    });

    return families;
  };

  const addProductsToFamily = async (familyId, familyName) => {
    let productIds = addingAll
      ? products.map((product) => product.product.product_id)
      : [selectedProduct.product.product_id];

    // Filtering out products that already have this family
    productIds = productIds.filter((id) => {
      const product = products.filter(
        (product) => product.product.product_id === id
      )[0];
      if (product.product_families.some((fam) => fam.id === familyId))
        return false;
      return true;
    });

    if (productIds.length < 1) {
      dispatch(
        addNotification({
          text: `Product(s) already belong to family.`,
          icon: <Icon color="yellow" name="warning circle" />,
        })
      );
      return setModal("");
    }

    try {
      await axios.post(`/families/${familyId}/members`, { productIds });

      setProducts((prev) =>
        prev.map((product) => {
          if (productIds.includes(product.product.product_id))
            return {
              ...product,
              product_families: [
                ...product.product_families,
                { id: familyId, family: familyName },
              ],
            };
          else return product;
        })
      );

      dispatch(
        addNotification({
          text: addingAll
            ? `Added family to all products!`
            : `Added family to product!`,
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addNotification({
          text: `Error adding family to product(s).`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setModal("");
    }
  };

  const removeFamilyAll = async (familyId) => {
    const productIds = products
      .filter((product) =>
        product.product_families.some((fam) => fam.id === familyId)
      )
      .map((product) => product.product.product_id);

    try {
      for (const productId of productIds) {
        await axios.delete(`/families/${familyId}/members/${productId}`);
      }

      setProducts((prev) =>
        prev.map((item) => {
          if (productIds.includes(item.product.product_id))
            return {
              ...item,
              product_families: item.product_families.filter(
                (fam) => fam.id !== familyId
              ),
            };
          else return item;
        })
      );

      dispatch(
        addNotification({
          text: "Removed family from products!",
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        addNotification({
          text: `Error removing family from products.`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setModal("");
    }
  };

  const removeProductFromFamily = async () => {
    setDeleteSubmitting(true);
    const productId = deleteSelection.product.product.product_id;
    const familyId = deleteSelection.family.id;

    try {
      await axios.delete(`/families/${familyId}/members/${productId}`);

      setProducts((prev) =>
        prev.map((item) => {
          if (item.product.product_id === productId)
            return {
              ...item,
              product_families: item.product_families.filter(
                (fam) => fam.id !== familyId
              ),
            };
          else return item;
        })
      );

      dispatch(
        addNotification({
          text: `Removed family from product(s)!`,
          icon: <Icon color="green" name="checkmark box" />,
        })
      );
    } catch (err) {
      dispatch(
        addNotification({
          text: `Error removing family from product(s).`,
          icon: <Icon color="red" name="warning circle" />,
        })
      );
    } finally {
      setModal("");
      setDeleteSubmitting(false);
    }
  };

  const deleteFamilyFromState = (id) => {
    setProducts((prev) =>
      prev.map((product) => ({
        ...product,
        product_families: product.product_families.filter(
          (fam) => fam.id !== id
        ),
      }))
    );
  };

  const updateFamilyName = (id, familyName) => {
    setFamilyToView({ familyName, familyId: id });

    setProducts((prev) =>
      prev.map((product) => {
        if (!product.product_families.some((fam) => fam.id === id))
          return product;

        return {
          ...product,
          product_families: product.product_families.map((fam) => {
            if (fam.id === id) return { ...fam, familyName };

            return fam;
          }),
        };
      })
    );
  };

  if (modal === "add")
    return (
      <AddFamilySelect
        open={modal === "add"}
        close={() => setModal("")}
        addingAll={addingAll}
        addProductsToFamily={addProductsToFamily}
      />
    );

  if (modal === "deleteAll")
    return (
      <DeleteFamilySelect
        open={modal === "deleteAll"}
        families={getAllUniqueProductFamilies()}
        close={() => setModal("")}
        remove={removeFamilyAll}
      />
    );

  if (modal === "delete")
    return (
      <Modal open={modal === "delete"} size="tiny">
        <Modal.Header>Remove family association</Modal.Header>
        <Modal.Content>
          <p>
            Would you like to remove family:{" "}
            <strong>
              {deleteSelection.family && deleteSelection.family.family}
            </strong>{" "}
            from product:{" "}
            <strong>
              {deleteSelection.product &&
                deleteSelection.product.product.product_title}
            </strong>
            ?
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={() => setModal("")}>Cancel</Button>
            <Button
              color="red"
              disabled={deleteSubmitting}
              onClick={removeProductFromFamily}
            >
              Delete
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

  if (modal === "familyPanel")
    return (
      <FamilyPanel
        close={() => setModal("")}
        open={modal === "familyPanel"}
        family={familyToView}
        updateFamilyName={updateFamilyName}
        deleteFamilyFromState={deleteFamilyFromState}
      />
    );

  return (
    <Modal open={open} onClose={close} size="small">
      <Modal.Header>
        <Grid columns={2}>
          <Grid.Column floated="left" verticalAlign="middle">
            <Header floated="left">Edit Product Families</Header>
          </Grid.Column>
          <Grid.Column floated="right">
            <Grid columns={2}>
              <Grid.Column>
                <Button
                  icon
                  size="small"
                  color="red"
                  labelPosition="left"
                  title="Remove all products from a family"
                  onClick={() => {
                    setModal("deleteAll");
                  }}
                >
                  <Icon name="minus square" />
                  Family All
                </Button>
              </Grid.Column>
              <Grid.Column>
                <Button
                  icon
                  size="small"
                  color="green"
                  labelPosition="left"
                  title="Add all products to a family"
                  onClick={() => {
                    setModal("add");
                    setAddingAll(true);
                  }}
                >
                  <Icon name="plus square" />
                  Family All
                </Button>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Modal.Header>

      <Modal.Content scrolling>
        <List relaxed divided>
          {products.map((product, i) => (
            <List.Item
              key={product.product.product_id}
              style={
                i % 2 === 0
                  ? { backgroundColor: "#e8e8e8", padding: "1em" }
                  : { padding: "1em" }
              }
            >
              <Grid columns={2}>
                <Grid.Column width={12}>
                  <List.Content>
                    <p>
                      <strong>Title:</strong> {product.product.product_title}
                    </p>

                    <div>
                      <strong style={{ paddingRight: "8px" }}>Families:</strong>{" "}
                      {product.product_families.map((family) => (
                        <Label
                          size="medium"
                          className={`${
                            familyHoverId ===
                              `family-label-${product.product.product_id}-${family.id}` &&
                            "family-label-hovered"
                          }`}
                          key={family.id}
                          style={{
                            backgroundColor: "white",
                            boxShadow: "0 1px 4px rgba(0, 0, 0, 0.2)",
                            marginRight: "8px",
                            transition: "all 0.2s ease",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={() => openFamilyPanel(family)}
                              id={`family-label-${product.product.product_id}-${family.id}`}
                              onMouseEnter={(e) =>
                                setFamilyHoverId(e.target.id)
                              }
                              onMouseLeave={() => setFamilyHoverId(null)}
                              title="View family panel and options"
                              style={{ cursor: "pointer" }}
                            >
                              {family.family}
                            </span>
                            <Icon
                              circular
                              id={`delete-icon-${product.product.product_id}-${family.id}`}
                              name="trash"
                              color="red"
                              title="Remove this product from this family"
                              onMouseEnter={(e) =>
                                setDeleteHoverId(e.target.id)
                              }
                              onMouseLeave={() => setDeleteHoverId(null)}
                              inverted={
                                deleteHoverId !==
                                `delete-icon-${product.product.product_id}-${family.id}`
                              }
                              style={{ cursor: "pointer", marginLeft: "8px" }}
                              onClick={() => {
                                setDeleteSelection({ family, product });
                                setModal("delete");
                              }}
                            />
                          </div>
                        </Label>
                      ))}
                    </div>
                  </List.Content>
                </Grid.Column>
                <Grid.Column floated="right" verticalAlign="middle" width={4}>
                  <Button
                    icon
                    labelPosition="left"
                    floated="right"
                    size="tiny"
                    color="green"
                    title="Add this product to a family"
                    onClick={() => {
                      setModal("add");
                      setSelectedProduct(product);
                      setAddingAll(false);
                    }}
                  >
                    <Icon name="plus square" />
                    Family
                  </Button>
                </Grid.Column>
              </Grid>
            </List.Item>
          ))}
        </List>
      </Modal.Content>
      <Divider />
      <Modal.Content>
        <Grid columns={2}>
          <Grid.Column>
            <Button onClick={close}>Cancel</Button>
          </Grid.Column>

          <Grid.Column>
            <Button primary floated="right" onClick={close}>
              Done
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

export default FamilyManagement;
